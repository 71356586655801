import React from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

export default ({ value = "miles", onChange }) => (
  <ToggleButtonGroup
    value={value}
    exclusive
    onChange={onChange}
    aria-label="Unit"
    size="large"
  >
    <ToggleButton value="miles" aria-label="left aligned">
      Mi
    </ToggleButton>
    <ToggleButton value="kilometers" aria-label="centered">
      Km
    </ToggleButton>
  </ToggleButtonGroup>
);
