import { Button } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import InstaButton from "./InstaButton";

export default () => {
  return (
    <div
      className="App"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        flexDirection: "column",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: 500,
        maxWidth: "100%",
        padding: 24,
        margin: "auto",
        boxSizing: "border-box"
      }}
    >
      <header className="App-header" style={{ minHeight: 0 }}>
        <h1
          style={{
            textAlign: "left",
            padding: 0,
            marginTop: -50,
            marginBottom: 24
          }}
        >
          <span>
            YEAR IN <span style={{ color: "#F05537" }}>RUN</span>NING
          </span>
        </h1>
      </header>
      <div
        style={{
          fontFamily: "Open Sans",
          margin: "16px 0px 32px 0"
        }}
      ></div>
      <div
        style={{
          fontFamily: "Open Sans"
        }}
      >
        <div style={{}}>
          Visualizing running in any way we can, from year end stats to
          cheermaps, we’re always looking to showcase running in cool and
          innovative ways!
        </div>
        <div style={{ marginTop: 32 }}>
          <InstaButton />
        </div>
        <div style={{ textAlign: "center", marginTop: 48 }}>
          <div style={{ paddingBottom: 8, fontWeight: "700" }}>
            Recent Projects
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center"
            }}
          >
            <div style={{ paddingLeft: 8, paddingRight: 8 }}>
              <Link to="/fantasy/ws2021" target="_blank">
                Western States Fantasy
              </Link>
            </div>
            {" • "}
            <div style={{ paddingLeft: 8, paddingRight: 8 }}>
              <Link to="/2022" target="_blank">
                Year in Running 2022
              </Link>
            </div>
            <div style={{ paddingLeft: 8, paddingRight: 8 }}>
              <Link to="/2021" target="_blank">
                Year in Running 2021
              </Link>
            </div>
            <div style={{ paddingLeft: 8, paddingRight: 8 }}>
              <Link to="/2020" target="_blank">
                Year in Running 2020
              </Link>
            </div>
            <div style={{ paddingLeft: 8, paddingRight: 8 }}>
              <Link to="/2019" target="_blank">
                Year in Running 2019
              </Link>
            </div>
            {" • "}
            <div style={{ paddingLeft: 8, paddingRight: 8 }}>
              <a
                href="https://cheermap.run/"
                target="_blank"
                rel="noopener noreferrer"
              >
                cheermap.run
              </a>
            </div>
            {" • "}
            <div style={{ paddingLeft: 8, paddingRight: 8 }}>
              <a
                href="https://chaskichallenge.yearinrunning.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Chaski Challenge
              </a>
            </div>
            <div style={{ paddingLeft: 8, paddingRight: 8 }}>
              <a
                href="https://chaskichallenge.yearinrunning.com/marathon-relay"
                target="_blank"
                rel="noopener noreferrer"
              >
                Athletic Brewing Marathon Relay
              </a>
            </div>
            {" • "}
            <div style={{ paddingLeft: 8, paddingRight: 8 }}>
              <a
                href="https://eveningrun.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Evening Run
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
