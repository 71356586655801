import React from "react";

export default class Share extends React.Component {
  render() {
    return (
      <div>
        <canvas width={100} height={100} ref={x => (this.canvas = x)} />
        <button>Share</button>
      </div>
    );
  }
}
