import { Button, CircularProgress, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { GoogleLoginButton } from "react-social-login-buttons";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import GoogleIcon from "./google-icon";
import StravaAuth from "./strava-auth";

const firebase = require("firebase");
const theme = {
  darkMode: true,
  showLogo: true
};
const muiTheme = createMuiTheme({
  darkMode: theme.darkMode,
  palette: {
    type: theme.darkMode ? "dark" : "light",
    primary: {
      // light: will be calculated from palette.primary.main,
      main: theme.primary || "#FFF"
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: theme.secondary || "#F05537"
      // dark: will be calculated from palette.secondary.main,
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2
  }
});

const Auth = () => {
  const [user, setUser] = useState(true);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      console.log(user);
      if (user) {
        setUser(user);
      } else {
        window.location = `/auth?redirect=/account`;
      }
    });
  }, []);

  if (!user) {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <ThemeProvider theme={muiTheme}>
      <h1>User Account</h1>
      <StravaAuth />
    </ThemeProvider>
  );
};
export default Auth;
