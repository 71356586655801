import React, { useEffect, useState } from "react";
import { FormattedDate } from "react-intl";
const firebase = require("firebase");

const ShowRace = (props) => {
  const [race, setRace] = useState();
  useEffect(() => {
    const getRace = firebase.functions().httpsCallable("racedb-get");
    getRace({ slug: props.match.params.slug }).then(({ data }) => {
      setRace(data);
    });
  }, []);

  if (!race) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {/* head helmet stuff here for seo */}
      <h1>
        <FormattedDate value={race.date} year="numeric" /> {race.name}
      </h1>
      <div>
        <h2>Race Day</h2>
        <FormattedDate
          value={race.date}
          day="numeric"
          month="long"
          year="numeric"
        />
      </div>
      <div>
        <h2>Registration</h2>
        <p>Registration is closed.</p>
      </div>
      <div>
        <h2>Weather Forecast</h2>
        <h2>Historical Weather</h2>
      </div>
      <div>
        <h2>Events</h2>
        <ul>
          {race.events.map((event) => (
            <div>
              <h3>{event.name}</h3>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ShowRace;
