import { Button, CircularProgress, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { GoogleLoginButton } from "react-social-login-buttons";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import GoogleIcon from "./google-icon";
import { useLocation } from "react-router-dom";

const firebase = require("firebase");

const theme = {
  darkMode: true,
  showLogo: true
};
const muiTheme = createMuiTheme({
  darkMode: theme.darkMode,
  palette: {
    type: theme.darkMode ? "dark" : "light",
    primary: {
      // light: will be calculated from palette.primary.main,
      main: theme.primary || "#FFF"
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: theme.secondary || "#F05537"
      // dark: will be calculated from palette.secondary.main,
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2
  }
});

const Styled = {
  AuthContainer: styled.div`
    padding: 16px 16px;
    margin: auto;
    width: 320px;
  `,
  EmailPass: styled.div`
    border-top: 1px solid #fffa;
    margin-top: 16px;
    padding-top: 8px;
  `,
  YIR: styled.div`
    font-size: 18px;
    font-weight: 700;
  `,
  h1: styled.h1`
    font-size: 18px;
    font-weight: 400;
    text-align: center;
  `
};

const Auth = () => {
  const [authenticating, setAuthenticating] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log(location);
        window.location = "/";
      } else {
        setLoading(false);
      }
    });
  }, []);

  const createUser = (email, password) => {
    setAuthenticating(true);
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user;
        //console.log(user);
        //window.close();
        setAuthenticating(false);
        // ...
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        setAuthenticating(false);
        console.log(errorMessage);
        // ..
      });
  };
  const loginWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        window.close();
        setAuthenticating(false);
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        setAuthenticating(false);
        console.log(errorMessage);
        // ...
      });
  };
  const login = (email, password) => {
    setAuthenticating(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user;
        console.log(user);
        window.close();
        setAuthenticating(false);
        // ...
      })
      .catch((error) => {
        var errorCode = error.code;
        setAuthenticating(false);
        var errorMessage = error.message;
        console.log(errorMessage);
        // ..
      });
  };
  if (authenticating || loading) {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <ThemeProvider theme={muiTheme}>
      <Styled.AuthContainer>
        <Styled.h1>
          Sign in to
          <Styled.YIR>
            YEAR IN <span style={{ color: "#F05537" }}>RUN</span>NING
          </Styled.YIR>
        </Styled.h1>
        <Button
          style={{ marginTop: 8, textAlign: "left" }}
          fullWidth
          variant="contained"
          color="primary"
          onClick={loginWithGoogle}
          startIcon={<GoogleIcon />}
        >
          Sign in with Google
        </Button>
        <Styled.EmailPass>
          <TextField
            fullWidth
            label="Email Address"
            type="email"
            id="email"
            variant="outlined"
            style={{ marginTop: 8 }}
          >
            Email
          </TextField>
          <TextField
            fullWidth
            label="Password"
            type="password"
            id="password"
            variant="outlined"
            style={{ marginTop: 8 }}
          >
            Password
          </TextField>
          <div>
            <Button
              fullWidth
              style={{ marginTop: 8 }}
              variant="contained"
              color="secondary"
              onClick={() =>
                login(
                  document.getElementById("email").value,
                  document.getElementById("password").value
                )
              }
            >
              Login
            </Button>
            <Button
              style={{ marginTop: 8 }}
              fullWidth
              variant="contained"
              color="primary"
              onClick={() =>
                createUser(
                  document.getElementById("email").value,
                  document.getElementById("password").value
                )
              }
            >
              Create
            </Button>
          </div>
        </Styled.EmailPass>
      </Styled.AuthContainer>
    </ThemeProvider>
  );
};
export default Auth;
