import React, { useEffect, useState } from "react";
const firebase = require("firebase");

const Races = () => {
  const [races, setRaces] = useState([]);
  useEffect(() => {
    const racesIndex = firebase.functions().httpsCallable("racedb-index");
    racesIndex().then(({ data }) => {
      setRaces(data);
    });
  }, []);
  return (
    <div>
      <h1>Races</h1>
      <ul>
        {races.map((race) => (
          <li>
            <a href={`/races/${race.slug}`}>{race.name}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Races;
