import React from "react";
import GeoCanvas from "./GeoCanvas";
import SummaryCanvas from "./SummaryCanvas";
import RaceCanvas from "./RaceCanvas";
import demoData from "./demoData";
import { getParameterByName, removeParameter } from "../../utils";
import MileToggle from "./MileToggle";
import {
  Checkbox,
  Select,
  TextField,
  FormControlLabel,
  ExpansionPanelDetails,
  ExpansionPanel,
  ExpansionPanelSummary,
  Button,
  InputLabel,
  Link,
  Fab
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ShareIcon from "@material-ui/icons/Share";
import DownloadIcon from "@material-ui/icons/GetApp";
import Loader from "./Loader";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const firebase = require("firebase");

const sampleCode = false; //"263792db5d843b655a09fe970978b1130533e77b";
const scope = "read,activity:read_all";
/*

*/

export default class StravaResults extends React.Component {
  constructor() {
    super();
    this.state = {
      result: undefined,
      unit: "miles",
      raceEdits: undefined
    };
  }

  componentDidMount() {
    const code = sampleCode || getParameterByName("code");
    if (!code) {
      alert("An error occurred, please try again");
      window.location = "/2022";
    }
    window.history.replaceState({}, document.title, window.location.pathname);
    if (true) {
      // firebase.functions().useFunctionsEmulator("http://localhost:5001");
      const stravaExchangeToken = firebase
        .functions()
        .httpsCallable("stravaExchangeToken2022");
      stravaExchangeToken({ code, scope }).then((result) => {
        this.setState({ result: result.data }, () => {
          const races = this.state.result.races.reduce(
            (acc, r) => ({
              ...acc,
              [r.id]: {
                id: r.id,
                name: r.name,
                distance: this.guessRaceDistance(r.distance),
                time: this.getTimeClock(r.moving_time),
                description: "",
                isRace: r.workout_type === 1,
                distanceDescriber: this.guessDistanceDescriber(r.distance)
              }
            }),
            {}
          );
          const potentialRaces = this.state.result.potentialRaces.reduce(
            (acc, r) => ({
              ...acc,
              [r.id]: {
                id: r.id,
                name: r.name,
                distance: this.guessRaceDistance(r.distance),
                time: this.getTimeClock(r.moving_time),
                description: `Selected because this activity had ${r.athlete_count} other athletes recording with you.`,
                isRace: false,
                distanceDescriber: this.guessDistanceDescriber(r.distance)
              }
            }),
            {}
          );
          this.setState({
            raceEdits: { ...races, ...potentialRaces }
          });
        });
      });
    } else {
      this.setState({ result: demoData.result }, () => {
        const races = this.state.result.races.reduce(
          (acc, r) => ({
            ...acc,
            [r.id]: {
              id: r.id,
              name: r.name,
              distance: this.guessRaceDistance(r.distance),
              time: this.getTimeClock(r.moving_time),
              description: "",
              isRace: r.workout_type === 1,
              distanceDescriber: this.guessDistanceDescriber(r.distance)
            }
          }),
          {}
        );
        const potentialRaces = this.state.result.potentialRaces.reduce(
          (acc, r) => ({
            ...acc,
            [r.id]: {
              id: r.id,
              name: r.name,
              distance: this.guessRaceDistance(r.distance),
              time: this.getTimeClock(r.moving_time),
              description: `Selected because this activity had ${r.athlete_count} other athletes recording with you.`,
              isRace: false,
              distanceDescriber: this.guessDistanceDescriber(r.distance)
            }
          }),
          {}
        );
        this.setState({
          raceEdits: { ...races, ...potentialRaces }
        });
      });
    }
  }

  getTimeClock(inputSeconds) {
    let hours = 0;
    let minutes = 0;
    let seconds = inputSeconds;
    while (seconds > 60 * 60) {
      seconds -= 60 * 60;
      hours++;
    }
    while (seconds > 60) {
      seconds = seconds - 60;
      minutes++;
    }
    seconds = Math.round(seconds);
    if (hours > 0) {
      return `${hours}:${window.pad(minutes)}:${window.pad(seconds)}`;
    }
    return `${window.pad(minutes)}:${window.pad(seconds)}`;
  }

  guessRaceDistance(distance) {
    if (distance > 40000 && distance < 44000) {
      return "26.2mi";
    } else if (distance > 19500 && distance < 23000) {
      return "13.1mi";
    } else if (distance > 14500 && distance < 17500) {
      return "10mi";
    } else if (distance > 8500 && distance < 11500) {
      return "10k";
    } else if (distance > 7500 && distance < 8500) {
      return "5mi";
    } else if (distance > 3600 && distance < 6000) {
      return "5k";
    } else {
      return "";
    }
  }

  guessDistanceDescriber(distance) {
    if (distance > 44000) {
      return "7_Ultra";
    } else if (distance > 40000 && distance < 44000) {
      return "6_Marathon";
    } else if (distance > 19500 && distance < 23000) {
      return "5_Half";
    } else if (distance > 14500 && distance < 17500) {
      return "4_10 Mile";
    } else if (distance > 8500 && distance < 11500) {
      return "3_10K";
    } else if (distance > 7500 && distance < 8500) {
      return "1_5 Mile";
    } else if (distance > 3600 && distance < 6000) {
      return "2_5K";
    } else {
      return "8_Other";
    }
  }

  renderFab() {
    if (this.state[`canvas-${this.state.indexToShare}`]) {
      return (
        <Fab
          variant="extended"
          color="secondary"
          aria-label="share"
          style={{ marginTop: 10 }}
          download={`${
            this.state[`canvas-${this.state.indexToShare}-name`]
          }-Year-In-Running`}
          href={this.state[`canvas-${this.state.indexToShare}`]}
          onClick={(e) => {
            function iOSversion() {
              if (/iP(hone|od|ad)/.test(navigator.platform)) {
                // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
                var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
                return [
                  parseInt(v[1], 10),
                  parseInt(v[2], 10),
                  parseInt(v[3] || 0, 10)
                ];
              }
            }
            const version = iOSversion();
            if (true || (version && (version[0] < 13 || version[1] < 2))) {
              e.preventDefault();
              this.setState({ downloadDialog: e.currentTarget.href });
            } else {
              this.slider.slickGoTo(Math.min(3, this.state.indexToShare + 1));
            }
          }}
        >
          <DownloadIcon style={{ margin: "0 10px 0 0" }} /> Save{" "}
          {this.state[`canvas-${this.state.indexToShare}-name`]}
        </Fab>
      );
    }
    return null;
  }

  closeDialog = () => {
    this.setState({ downloadDialog: false }, () => {
      this.slider.slickGoTo(Math.min(3, this.state.indexToShare + 1));
    });
  };

  render() {
    const { result, dataReady, raceEdits, unit } = this.state;

    const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />;
    });

    if (dataReady) {
      return (
        <div>
          <Dialog
            fullScreen
            open={!!this.state.downloadDialog}
            onClose={this.closeDialog}
          >
            <AppBar>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.closeDialog}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6">Download Image</Typography>
              </Toolbar>
            </AppBar>
            <div style={{ padding: 10 }}>
              <img
                src={this.state.downloadDialog || ""}
                alt={"test"}
                style={{ width: "100%", marginTop: 60 }}
              />
              <p style={{ fontFamily: "Open Sans", textAlign: "center" }}>
                Press and hold, then save the image to your device.
              </p>
              <div style={{ fontFamily: "Open Sans", textAlign: "center" }}>
                <Button onClick={this.closeDialog}>Close This Dialog</Button>
              </div>
            </div>
          </Dialog>
          <h1
            style={{
              textAlign: "left",
              padding: 0,
              width: 245,
              margin: "25px auto"
            }}
          >
            <a href="/" style={{ color: "#FFFFFF", textDecoration: "none" }}>
              <span style={{ fontSize: 16 }}>your</span>
              <br />
              <span>
                YEAR IN <span style={{ color: "#BA2649" }}>RUN</span>NING
              </span>
            </a>
          </h1>
          <Slider
            {...{
              dots: true,
              arrows: true,
              centerMode: true,
              centerPadding: "30px",
              slidesToShow: 3,
              infinite: false,
              speed: 500,
              slidesToShow: 1,
              slidesToScroll: 1
            }}
            onInit={() => {
              this.setState({ indexToShare: 0 });
            }}
            afterChange={(index) => {
              this.setState({ indexToShare: index });
            }}
            ref={(x) => (this.slider = x)}
          >
            <div>
              <SummaryCanvas
                {...result}
                unit={unit}
                races={Object.keys(raceEdits)
                  .map((id) => raceEdits[id])
                  .filter((race) => race.isRace)}
                setURL={(url, name) =>
                  this.setState({
                    [`canvas-${0}`]: url,
                    [`canvas-${0}-name`]: name
                  })
                }
              />
              {this.renderFab()}
            </div>
            <div>
              <GeoCanvas
                {...result}
                setURL={(url, name) =>
                  this.setState({
                    [`canvas-${1}`]: url,
                    [`canvas-${1}-name`]: name
                  })
                }
              />
              {this.renderFab()}
            </div>
            <div>
              <RaceCanvas
                races={Object.keys(raceEdits)
                  .map((id) => raceEdits[id])
                  .filter((race) => race.isRace)}
                setURL={(url, name) =>
                  this.setState({
                    [`canvas-${2}`]: url,
                    [`canvas-${2}-name`]: name
                  })
                }
              />
              {this.renderFab()}
            </div>
            <div>
              <div style={{ fontFamily: "Open Sans", padding: "25px 35px" }}>
                Feel free to share your stats wherever you'd like, you earned
                it!
                <br />
                <br />
                <h2 style={{ margin: 0, fontSize: 20 }}>Want to give back?</h2>
                Please consider a $5 donation to help diversity in the outdoors
                with{" "}
                <a
                  href="https://www.instagram.com/hbcusoutside/"
                  target="_blank"
                >
                  @hbcusoutside
                </a>
                <div style={{ marginTop: 8 }}>
                  <Button
                    variant="contained"
                    color="white"
                    href="https://www.hbcusoutside.com/getinvolved"
                    target="_blank"
                  >
                    Donate Now
                  </Button>
                </div>
                <br />
                And also please share{" "}
                <a
                  style={{ color: "#FFFFFF" }}
                  href="https://app.yearinrunning.com/2022"
                >
                  yearinrunning.com
                </a>{" "}
                with your friends and competitors!
              </div>
              {navigator.share && (
                <Fab
                  variant="extended"
                  color="secondary"
                  aria-label="share"
                  style={{ marginTop: 10 }}
                  onClick={() => {
                    navigator
                      .share({
                        title: "Year In Running",
                        text: "Visualize your year in running!",
                        url: "https://app.yearinrunning.com/2022"
                      })
                      .then(() => {})
                      .catch((error) =>
                        alert("Sorry, something went wrong :(")
                      );
                  }}
                >
                  <ShareIcon style={{ margin: "0 10px 0 0" }} /> Share
                </Fab>
              )}
            </div>
            <div>
              <p style={{ fontFamily: "Open Sans", padding: "25px 15px" }}>
                Want to learn more about future running projects? Enter your
                email and we'll be sure to keep you updated!
              </p>
              <Button
                variant="contained"
                color="secondary"
                href="https://forms.gle/WLYrpdBdTjiAT1Dk9"
                target="_blank"
              >
                Keep Me Updated!
              </Button>
              <div style={{ marginTop: 60 }}>
                Or follow{" "}
                <a
                  style={{ color: "#FFF", fontFamily: "Open Sans" }}
                  href="https://www.instagram.com/yearinrunning/"
                >
                  @yearinrunning
                </a>{" "}
                on instagram
              </div>
            </div>
          </Slider>
        </div>
      );
    } else if (result && raceEdits) {
      return (
        <div style={{ maxWidth: 500, margin: "auto" }}>
          <div>
            <h2 style={{ margin: 10 }}>Select Units</h2>
            <MileToggle
              onChange={(event, unit) => {
                if (unit) {
                  this.setState({ unit });
                }
              }}
              value={this.state.unit}
            />
          </div>
          <h2>Potential Races</h2>
          <div
            style={{
              padding: "8px 20px",
              marginTop: -16,
              textAlign: "left",
              fontFamily: "Open Sans",
              fontStyle: "italic"
            }}
          >
            Please review and edit the races we've found. If you do not see an
            activity that is a race, go into Strava to mark it as a race and try
            again.
          </div>
          <div style={{ padding: "0 16px" }}>
            {Object.keys(raceEdits).map((id) => {
              const race = raceEdits[id];
              return (
                <ExpansionPanel key={id}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                    size="small"
                  >
                    <FormControlLabel
                      aria-label="Acknowledge"
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      control={
                        <Checkbox
                          checked={race.isRace}
                          onChange={(e) => {
                            this.setState({
                              raceEdits: {
                                ...raceEdits,
                                [race.id]: {
                                  ...race,
                                  isRace: e.currentTarget.checked
                                }
                              }
                            });
                          }}
                        />
                      }
                      style={{ textAlign: "left" }}
                      label={`${race.name} (${race.time})`}
                    />
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div>
                      <div
                        style={{
                          marginBottom: 16,
                          marginTop: -16,
                          textAlign: "left",
                          fontFamily: "Open Sans",
                          fontStyle: "italic"
                        }}
                      >
                        {race.description}{" "}
                        <Link
                          variant="body2"
                          href={`https://www.strava.com/activities/${race.id}`}
                          target="_blank"
                        >
                          View on Strava
                        </Link>
                      </div>
                      <div>
                        <TextField
                          label="Race Name"
                          style={{ width: "100%", marginBottom: 10 }}
                          value={race.name}
                          onChange={(e) => {
                            this.setState({
                              raceEdits: {
                                ...raceEdits,
                                [race.id]: {
                                  ...race,
                                  name: e.currentTarget.value
                                }
                              }
                            });
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        <TextField
                          label="Race Time"
                          value={race.time}
                          onChange={(e) => {
                            this.setState({
                              raceEdits: {
                                ...raceEdits,
                                [race.id]: {
                                  ...race,
                                  time: e.currentTarget.value
                                }
                              }
                            });
                          }}
                        />
                        <div style={{ width: 16 }} />
                        <TextField
                          label="Race Distance"
                          value={race.distance}
                          onChange={(e) => {
                            this.setState({
                              raceEdits: {
                                ...raceEdits,
                                [race.id]: {
                                  ...race,
                                  distance: e.currentTarget.value
                                }
                              }
                            });
                          }}
                        ></TextField>
                      </div>
                      <InputLabel
                        htmlFor="filled-age-native-simple"
                        style={{ marginTop: 16, textAlign: "left" }}
                      >
                        Distance Type
                      </InputLabel>
                      <Select
                        native
                        value={race.distanceDescriber}
                        style={{ width: "100%" }}
                        onChange={(e, value) => {
                          this.setState({
                            raceEdits: {
                              ...raceEdits,
                              [race.id]: {
                                ...race,
                                distanceDescriber: value
                              }
                            }
                          });
                        }}
                        inputProps={{
                          name: "age",
                          id: "age-native-simple"
                        }}
                        size="medium"
                      >
                        <option value={"2_5K"}>5K</option>
                        <option value={"3_10K"}>10K</option>
                        <option value={"5_Half"}>Half Marathon</option>
                        <option value={"6_Marathon"}>Marathon</option>
                        <option value={"7_Ultra"}>Ultra</option>
                        <option value={"1_5 Mile"}>5 Mile</option>
                        <option value={"4_10 Mile"}>10 Mile</option>
                        <option value={"8_Other"}>Other</option>
                      </Select>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              );
            })}
          </div>
          <div style={{ marginTop: 16, padding: "0 16px" }}>
            <Button
              variant="contained"
              color="secondary"
              style={{
                width: "100%",
                marginTop: 8,
                marginBottom: 120
              }}
              onClick={() =>
                this.setState({ dataReady: true }, () => {
                  window.scrollTo(0, 0);
                })
              }
            >
              Next
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            position: "relative",
            width: 200,
            height: 200,
            margin: "auto",
            marginTop: 25,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div style={{ position: "absolute", top: 0, left: 0 }}>
            <Loader />
          </div>
          <div className="fader">LOADING...</div>
        </div>
      );
    }
  }
}
