import React from "react";
import bkg_2 from "./bkg_2.jpg";

export default class Canvas extends React.Component {
  componentDidMount() {
    setTimeout(() => this.draw(), 100);
    setTimeout(() => this.draw(), 1500);
    setTimeout(() => this.draw(), 5500);
    setTimeout(() => this.draw(), 10000);
  }
  draw() {
    const {
      geo: { cities, countries, states }
    } = this.props;
    const countryList = Object.keys(countries).map((key) => ({
      key,
      count: countries[key]
    }));
    const stateList = Object.keys(states).map((key) => ({
      key,
      count: states[key]
    }));
    const cityList = Object.keys(cities).map((key) => ({
      key,
      count: cities[key]
    }));
    this.drawImage(
      countryList
        .sort((a, b) => (a.count < b.count ? -1 : 1))
        .reverse()
        .map((x) => x.key),
      stateList
        .sort((a, b) => (a.count < b.count ? -1 : 1))
        .reverse()
        .map((x) => x.key),
      cityList
        .sort((a, b) => (a.count < b.count ? -1 : 1))
        .reverse()
        .map((x) => x.key)
    );
  }
  drawImage(countries = [], states = [], cities = []) {
    var ctx = this.canvas.getContext("2d");
    ctx.fillStyle = "#000";
    ctx.drawImage(this.bkg_2, 0, 0, 1080, 1080);

    ctx.fillStyle = "#BA2649";
    ctx.font = "66px Bebas Neue";
    const countryString =
      countries.length === 1 ? "1 country" : `${countries.length} countries`;
    const stateString =
      states.length === 1 ? "1 state" : `${states.length} states`;
    const cityString =
      cities.length === 1 ? "1 city" : `${cities.length} cities`;
    const line1 = `You ran in ${countryString}, ${stateString}`;
    const line2 = `and ${cityString} but mostly in`;
    const line3 = `${cities[0].split(",")[0]}`;
    ctx.fillText(line1, 120, 175);
    ctx.fillText(line2, 120, 175 + 73);
    ctx.fillText(line3, 120, 175 + 73 + 73);

    ctx.fillStyle = "#F3F5F9";
    ctx.font = "24px Open Sans";
    const col1 = cities.slice(0, Math.ceil(cities.length / 3));
    const col2 = cities.slice(
      Math.ceil(cities.length / 3),
      Math.ceil((cities.length * 2) / 3)
    );
    const col3 = cities.slice(Math.ceil((cities.length * 2) / 3));
    col1.forEach((city, i) => {
      ctx.fillText(city, 120, 410 + 39 * i);
    });
    col2.forEach((city, i) => {
      ctx.fillText(city, 120 + 290, 410 + 39 * i);
    });
    col3.forEach((city, i) => {
      ctx.fillText(city, 120 + 290 + 290, 410 + 39 * i);
    });

    this.props.setURL(
      this.canvas
        .toDataURL("image/jpeg")
        .replace("image/png", "image/octet-stream"),
      "Geo"
    );
  }
  render() {
    return (
      <div style={{ padding: 10 }}>
        <img
          ref={(x) => (this.bkg_2 = x)}
          src={bkg_2}
          alt={"bkg_2"}
          style={{ display: "none" }}
          onLoad={this.forceUpdate}
        />
        <canvas
          ref={(x) => (this.canvas = x)}
          style={{ width: "100%", maxWidth: 500, maxHeight: 500 }}
          height="1080"
          width="1080"
        ></canvas>
      </div>
    );
  }
}
