import React from "react";
import "./App.css";
import StravaAuth from "./StravaAuth";
import StravaResults from "./results/StravaResults";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Share from "./Share";

if (!String.prototype.padStart) {
  String.prototype.padStart = function padStart(targetLength, padString) {
    targetLength = targetLength >> 0; //truncate if number, or convert non-number to 0;
    padString = String(padString !== undefined ? padString : " ");
    if (this.length >= targetLength) {
      return String(this);
    } else {
      targetLength = targetLength - this.length;
      if (targetLength > padString.length) {
        padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
      }
      return padString.slice(0, targetLength) + String(this);
    }
  };
}

window.pad = (num) => {
  return num.toString().padStart(2, "0");
};

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#290143"
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#F05537"
      // dark: will be calculated from palette.secondary.main,
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2
  }
});

function App() {
  const pathname = window.location.pathname;
  if (pathname.match(/\/share/)) {
    return <Share />;
  } else if (pathname.match(/\/strava/)) {
    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <StravaResults />
        </div>
      </ThemeProvider>
    );
  } else if (pathname.match(/\//)) {
    const test = true; //window.location.pathname.indexOf("test") >= 0;
    return (
      <div className="App">
        <header className="App-header">
          <h1 style={{ textAlign: "left", padding: 0, marginTop: -50 }}>
            <span style={{ fontSize: 16 }}>your</span>
            <br />
            <span>
              YEAR IN <span style={{ color: "#F05537" }}>RUN</span>NING
            </span>
          </h1>
          {!test ? (
            <p>
              20<span style={{ color: "#F05537" }}>19</span> Coming Soon
            </p>
          ) : (
            <StravaAuth />
          )}
        </header>
      </div>
    );
  }
  return <div>404</div>;
}

export default App;
