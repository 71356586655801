import React from "react";
import SquareCanvas from "./SquareCanvas";
import StoryCanvas from "./StoryCanvas";
import demoData from "./demoData";
import { getParameterByName, removeParameter } from "../../utils";
import MileToggle from "./MileToggle";
import {
  Checkbox,
  Select,
  TextField,
  FormControlLabel,
  ExpansionPanelDetails,
  ExpansionPanel,
  ExpansionPanelSummary,
  Button,
  InputLabel,
  Link,
  Fab
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ShareIcon from "@material-ui/icons/Share";
import DownloadIcon from "@material-ui/icons/GetApp";
import Loader from "./Loader";
import InstaButton from "../../InstaButton";
const firebase = require("firebase");

const sampleCode = false; //"263792db5d843b655a09fe970978b1130533e77b";
const scope = "read,activity:read_all";
/*

*/

function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod"
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

export default class StravaResults extends React.Component {
  constructor() {
    super();
    this.state = {
      result: undefined,
      indexToShare: 0,
      unit: "miles"
    };
  }

  componentDidMount() {
    const code = sampleCode || getParameterByName("code");
    if (true && !code) {
      alert("An error occurred, please try again");
      window.location = "/2020";
    }
    window.history.replaceState({}, document.title, window.location.pathname);
    if (true) {
      // firebase.functions().useFunctionsEmulator("http://localhost:5001");
      const stravaExchangeToken = firebase
        .functions()
        .httpsCallable("stravaExchangeToken2020");
      stravaExchangeToken({ code, scope }).then((result) => {
        this.setState({ result: result.data });
      });
    } else {
      this.setState({ result: demoData.result });
    }
  }

  getTimeClock(inputSeconds) {
    let hours = 0;
    let minutes = 0;
    let seconds = inputSeconds;
    while (seconds > 60 * 60) {
      seconds -= 60 * 60;
      hours++;
    }
    while (seconds > 60) {
      seconds = seconds - 60;
      minutes++;
    }
    seconds = Math.round(seconds);
    if (hours > 0) {
      return `${hours}:${window.pad(minutes)}:${window.pad(seconds)}`;
    }
    return `${window.pad(minutes)}:${window.pad(seconds)}`;
  }

  renderFab(index) {
    if (this.state[`canvas-${index}`]) {
      return (
        <Fab
          variant="extended"
          color="secondary"
          aria-label="share"
          style={{ marginTop: 10 }}
          download={`${this.state[`canvas-${index}-name`]}-Year-In-Running`}
          href={this.state[`canvas-${index}`]}
          onClick={async (e) => {
            if (false && navigator.share) {
              const blob = await (await fetch(e.currentTarget.href)).blob();
              const file = new File([blob], "year-in-running.jpg", {
                type: blob.type
              });
              const success = navigator
                .share({
                  title: "Year In Running",
                  text: "Visualize your year in running!",
                  files: [file]
                })
                .then(() => {
                  return true;
                })
                .catch((error) => {
                  return false;
                });
              if (success) {
                return;
              }
            }
            function iOSversion() {
              if (/iP(hone|od|ad)/.test(navigator.platform)) {
                // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
                var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
                return [
                  parseInt(v[1], 10),
                  parseInt(v[2], 10),
                  parseInt(v[3] || 0, 10)
                ];
              }
            }
            const version = iOSversion();
            if (true || (version && (version[0] < 13 || version[1] < 2))) {
              e.preventDefault();
              this.setState({ downloadDialog: e.currentTarget.href });
            }
          }}
        >
          <ShareIcon style={{ margin: "0 10px 0 0" }} /> Share{" "}
          {this.state[`canvas-${index}-name`]}
        </Fab>
      );
    }
    return null;
  }

  closeDialog = () => {
    this.setState({ downloadDialog: false }, () => {});
  };

  render() {
    const { result, unit } = this.state;

    const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />;
    });

    if (result) {
      return (
        <div>
          <Dialog
            fullScreen
            open={!!this.state.downloadDialog}
            onClose={this.closeDialog}
          >
            <AppBar>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.closeDialog}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6">Download Image</Typography>
              </Toolbar>
            </AppBar>
            <div style={{ padding: 10 }}>
              <img
                src={this.state.downloadDialog || ""}
                alt={"test"}
                style={{ width: "100%", marginTop: 60 }}
              />
              <p style={{ fontFamily: "Open Sans", textAlign: "center" }}>
                Press and hold, then save the image to your device.
              </p>
              <div style={{ fontFamily: "Open Sans", textAlign: "center" }}>
                <Button onClick={this.closeDialog}>Close This Dialog</Button>
              </div>
            </div>
          </Dialog>
          <h1
            style={{
              textAlign: "left",
              padding: 0,
              width: 245,
              margin: "16px auto"
            }}
          >
            <a href="/" style={{ color: "#FFFFFF", textDecoration: "none" }}>
              <span style={{ fontSize: 16 }}>your</span>
              <br />
              <span>
                YEAR IN <span style={{ color: "#F05537" }}>RUN</span>NING
              </span>
            </a>
          </h1>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap"
            }}
          >
            <div style={{ marginBottom: 48 }}>
              <div style={{ marginBottom: 32 }}>
                <MileToggle
                  onChange={(event, unit) => {
                    if (unit) {
                      this.setState({ unit });
                    }
                  }}
                  value={this.state.unit}
                />
              </div>
              <SquareCanvas
                {...result}
                unit={unit}
                setURL={(url, name) =>
                  this.setState({
                    [`canvas-${1}`]: url,
                    [`canvas-${1}-name`]: name
                  })
                }
                art={this.state.art}
              />
              {this.renderFab(1)}
            </div>
            <div>
              <StoryCanvas
                {...result}
                unit={unit}
                setURL={(url, name) =>
                  this.setState({
                    [`canvas-${0}`]: url,
                    [`canvas-${0}-name`]: name
                  })
                }
                art={this.state.art}
              />
              {this.renderFab(0)}
            </div>
          </div>

          <div>
            <div
              style={{
                textAlign: "left",
                fontFamily: "Open Sans",
                padding: "35px 35px"
              }}
            >
              <h2 style={{ margin: 0, fontSize: 20 }}>Want to give back?</h2>
              Please consider a $5 donation to help diversity in the outdoors
              with{" "}
              <a href="https://www.instagram.com/hbcusoutside/" target="_blank">
                @hbcusoutside
              </a>
              <div style={{ marginTop: 8 }}>
                <Button
                  variant="contained"
                  color="white"
                  href="https://www.hbcusoutside.com/getinvolved"
                  target="_blank"
                >
                  Donate Now
                </Button>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                textAlign: "left",
                fontFamily: "Open Sans",
                padding: "8px 35px"
              }}
            >
              <h2 style={{ margin: 0, fontSize: 20 }}>
                Learn about Year In Running
              </h2>
              Connect with us{" "}
              <a
                href="https://www.instagram.com/yearinrunning/"
                target="_blank"
              >
                @yearinrunning
              </a>{" "}
              to stay up to date on all our future products!
              <div style={{ marginTop: 8 }}>
                <InstaButton />
              </div>
            </div>
          </div>
          {!iOS() && (
            <div style={{ position: "fixed", top: 0, right: 0 }}>
              <Button onClick={() => this.setState({ art: !!!this.state.art })}>
                <span style={{ color: "#FFF" }}>
                  {this.state.art ? "Nevermind...not art!" : "Art?"}
                </span>
              </Button>
            </div>
          )}
          <div style={{ marginTop: 16 }}>
            {navigator.share && (
              <Fab
                variant="extended"
                color="secondary"
                aria-label="share"
                style={{ marginTop: 10 }}
                onClick={() => {
                  navigator
                    .share({
                      title: "Year In Running",
                      text: "Visualize your year in running!",
                      url: "https://app.yearinrunning.com/2020"
                    })
                    .then(() => {})
                    .catch((error) => {});
                }}
              >
                <ShareIcon style={{ margin: "0 10px 0 0" }} /> Share Year In
                Running
              </Fab>
            )}
          </div>
          <div style={{ height: 45 }}></div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            position: "relative",
            width: 200,
            height: 200,
            margin: "auto",
            marginTop: 25,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div style={{ position: "absolute", top: 0, left: 0 }}>
            <Loader />
          </div>
          <div className="fader">LOADING...</div>
        </div>
      );
    }
  }
}
