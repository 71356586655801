import React, { useEffect } from "react";
import { getParameterByName } from "../utils";
import ConnectImg from "./connect.svg";

const firebase = require("firebase");

const requestAccess = () => {
  const client_id = "20506";
  const redirect =
    "http://localhost:3000/account" ||
    `https://app.yearinrunning.com/2019/strava`;
  // http://localhost:5001/year-in-running/us-central1/stravaExchange
  const url = `http://www.strava.com/oauth/authorize?client_id=${client_id}&response_type=code&redirect_uri=${redirect}&approval_prompt=force&scope=activity:read_all`;
  window.location = url;
};

const backfillActivities = () => {
  // firebase.functions().useFunctionsEmulator("http://localhost:5001");
  const backfillActivities = firebase
    .functions()
    .httpsCallable("strava-backfillActivities");
  backfillActivities().then((result) => {
    console.log(result);
  });
};

export default () => {
  useEffect(() => {
    const code = getParameterByName("code");
    const scope = getParameterByName("scope");
    if (code) {
      // firebase.functions().useFunctionsEmulator("http://localhost:5001");
      const stravaExchange = firebase.functions().httpsCallable("strava-oauth");
      stravaExchange({ code, scope }).then((result) => {
        console.log(code, scope);
      });
    }
  }, []);

  return (
    <>
      <button
        onClick={requestAccess}
        style={{
          border: "none",
          background: "none",
          borderRadius: "none",
          WebkitAppearance: "none"
        }}
      >
        <img src={ConnectImg} alt="Connect With Strava" />
      </button>
      <button
        onClick={backfillActivities}
        style={{
          border: "none"
        }}
      >
        Backfill Activities
      </button>
    </>
  );
};
