export const getParameterByName = name => {
  let value = "";
  const beforeHashName = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  const afterHashName = name.replace(/[\[\]]/g, "\\$&");

  var regex = new RegExp("[\\?&]" + beforeHashName + "=([^&/?#]*)"),
    results = regex.exec(window.location.search);

  value =
    results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));

  // Search after hash if name not found in location.search
  if (!value) {
    regex = new RegExp("[?&]" + afterHashName + "(=([^&#]*)|&|#|$)");
    results = regex.exec(window.location.href);
    if (!results) return "";
    if (!results[2]) return "";
    value = decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  return value;
};

// http://stackoverflow.com/questions/1634748/how-can-i-delete-a-query-string-parameter-in-javascript
export const removeParameter = (url, parameter) => {
  //prefer to use l.search if you have a location/link object
  var urlparts = url.split("?");
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter) + "=";
    var pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0; ) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    url = urlparts[0] + "?" + pars.join("&");
    return url;
  } else {
    return url;
  }
};
