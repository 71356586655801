import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import YIR2019 from "./2019/App";
import YIR2020 from "./2020/App";
import YIR2021 from "./2021/App";
import YIR2022 from "./2022/App";
import Auth from "./Auth";
import Account from "./Auth/account";
import WS2021 from "./Fantasy/WS-2021";
import LandingPage from "./LandingPage";
import PaceCalculator from "./PaceCalculator";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Races from "./Races";
import ShowRace from "./Races/show";
import { IntlProvider } from "react-intl";

const firebase = require("firebase");
// Required for side-effects
require("firebase/functions");
require("firebase/firestore");

firebase.initializeApp({
  apiKey: "AIzaSyC8yNmOh8qw0UMTitXW6LYPsKfCI8myJxA",
  authDomain: "year-in-running.firebaseapp.com",
  databaseURL: "https://year-in-running.firebaseio.com",
  projectId: "year-in-running",
  storageBucket: "year-in-running.appspot.com",
  //messagingSenderId: "31742967096",
  appId: "1:31742967096:web:538d03c51f451c797655cd"
});

// irebase.functions().useFunctionsEmulator("http://localhost:5001");

const RouterRoot = () => {
  return (
    <IntlProvider locale="en" defaultLocale="en">
      <Router>
        <div>
          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/auth">
              <Auth />
            </Route>
            <Route path="/account">
              <Account />
            </Route>
            <Route path="/2022">
              <YIR2022 />
            </Route>
            <Route path="/fantasy/ws2021">
              <WS2021 />
            </Route>
            <Route path="/races/:slug" component={ShowRace} />
            <Route path="/races" component={Races} />
            <Route path="/2021">
              <YIR2021 />
            </Route>
            <Route path="/2020">
              <YIR2020 />
            </Route>
            <Route path="/2019">
              <YIR2019 />
            </Route>
            <Route path="/pace-calculator">
              <PaceCalculator />
            </Route>
            <Route path="/">
              <LandingPage />
            </Route>
          </Switch>
        </div>
      </Router>
    </IntlProvider>
  );
};

ReactDOM.render(<RouterRoot />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
