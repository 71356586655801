import React from "react";
import ConnectImg from "./connect.svg";

const requestAccess = () => {
  const client_id = "20506";
  const url = `http://www.strava.com/oauth/authorize?client_id=${client_id}&response_type=code&redirect_uri=https://app.yearinrunning.com/2020/strava&approval_prompt=force&scope=activity:read_all`;
  //const url = `http://www.strava.com/oauth/authorize?client_id=${client_id}&response_type=code&redirect_uri=http://localhost:3001/2020/strava&approval_prompt=force&scope=activity:read_all`;
  window.location = url;
};

export default () => {
  return (
    <button
      onClick={requestAccess}
      style={{
        border: "none",
        background: "none",
        borderRadius: "none",
        WebkitAppearance: "none"
      }}
    >
      <img src={ConnectImg} alt="Connect With Strava" />
    </button>
  );
};
