import React, { useEffect, useState } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {
  Button,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from "@material-ui/core";
import styled from "styled-components";
import { getParameterByName } from "../utils";

const themes = {
  default: {
    darkMode: true,
    showLogo: true
  },
  chaski: {
    darkMode: false,
    secondary: "#000",
    primary: "#FFF",
    background: "#FFF",
    showLogo: false,
    metaWidth: 320
  }
};

const theme = themes[getParameterByName("theme")] || themes.default;
const scale = theme.metaWidth ? Math.min(1.0, theme.metaWidth / 411) : 1.0;

const getTotalSeconds = (hours, minutes, seconds) => {
  return (
    parseFloat(hours) * 60 * 60 + parseFloat(minutes) * 60 + parseFloat(seconds)
  );
};
const secondsToHoursMinutesSeconds = (seconds) => {
  const absSeconds = Math.abs(seconds);
  const hours = Math.floor(absSeconds / 60 / 60);
  const minutes = Math.floor((absSeconds - hours * 60 * 60) / 60);
  const secondsLeft = parseFloat(
    (absSeconds - minutes * 60 - hours * 60 * 60).toFixed(2)
  );
  return { hours, minutes, seconds: secondsLeft };
};
const knownConversions = {
  "mi:m": 1609.34,
  "km:m": 1000,
  "y:m": 0.9144,
  "m:m": 1,
  "1500m:m": 1500,
  "800m:m": 800,
  "400m:m": 400,
  "200m:m": 200,
  "100m:m": 100,
  "880y:m": 804.672,
  "440y:m": 402.336,
  "220y:m": 201.168,
  "smoot:m": 1.7018
};
const convertToUnits = (
  distance = 0,
  unitToConvertFrom = "mi",
  unitToConvertTo = "m",
  fixed = 2
) => {
  const d = parseFloat(distance);
  if (d === 0 || unitToConvertFrom === unitToConvertTo) {
    return d;
  }

  // always convert to meters, then convert back
  if (unitToConvertFrom === "m") {
    const calc = d / knownConversions[`${unitToConvertTo}:m`];
    return parseFloat(calc);
  } else if (unitToConvertTo === "m") {
    const calc = d * knownConversions[`${unitToConvertFrom}:m`];
    return parseFloat(calc);
  } else {
    const distanceInMeters = convertToUnits(d, unitToConvertFrom, "m");
    return convertToUnits(distanceInMeters, "m", unitToConvertTo);
  }
};
const pad = (num) => {
  if (num || num === 0) {
    return num.toString().padStart(2, "0");
  } else {
    return num;
  }
};
const floatPad = (num) => {
  if (num) {
    const str = num.toString();
    if (str.indexOf(".") === -1) {
      return str + ".0";
    } else {
      return str;
    }
  } else {
    return num;
  }
};
const getInt = (num, min, max) => {
  if (!num) {
    return undefined;
  }
  const int = parseInt(num, 10);
  if (isNaN(int)) {
    return 0;
  }
  return Math.min(max, Math.max(min, int));
};

const muiTheme = createMuiTheme({
  darkMode: theme.darkMode,
  palette: {
    type: theme.darkMode ? "dark" : "light",
    primary: {
      // light: will be calculated from palette.primary.main,
      main: theme.primary || "#FFF"
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: theme.secondary || "#F05537"
      // dark: will be calculated from palette.secondary.main,
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2
  }
});

const Styled = {
  TimeTextFieldContainer: styled.div`
    width: 60px;
    margin: 0 4px;
    &.seconds {
      width: 95px;
    }
  `,
  TimeTextField: styled(TextField)`
    input {
      font-size: 1.4rem;
    }
  `,
  DistanceTextFieldContainer: styled.div`
    width: 120px;
    margin: 0 4px;
  `,
  DistanceTextField: styled(TextField)`
    input {
      font-size: 1.4rem;
    }
  `,
  PaceTextFieldContainer: styled.div`
    width: 60px;
    margin: 0 4px;
    &.seconds {
      width: 95px;
    }
  `,
  PaceTextField: styled(TextField)`
    input {
      font-size: 1.4rem;
    }
  `,
  Select: styled(Select)`
    input {
      font-size: 1.4rem;
    }
  `
};

const possibleEventDistancesKm = [
  { label: "Marathon", km: 42.195 },
  { label: "1/2 Marathon", km: 21.0975 },
  { label: "10k", km: 10 },
  { label: "5k", km: 5 },
  { label: "5k", km: 5 },
  { label: "5mi", km: 8.04672 },
  { label: "20k", km: 20 },
  { label: "30k", km: 30 },
  { label: "50k", km: 50 },
  { label: "100k", km: 100 }
];

const Calculator = () => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [distance, setDistance] = useState(0.0);
  const [prevDistanceUnit, setPrevDistanceUnit] = useState("mi");
  const [distanceUnit, setDistanceUnit] = useState("mi");
  const [paceMinutes, setPaceMinutes] = useState(0);
  const [paceSeconds, setPaceSeconds] = useState(0);
  const [paceUnit, setPaceUnit] = useState("mi");
  const [prevPaceUnit, setPrevPaceUnit] = useState("mi");
  const [autoCalc, setAutoCalc] = useState();
  useEffect(() => {
    setDistance(convertToUnits(distance, prevDistanceUnit, distanceUnit));
    setPrevDistanceUnit(distanceUnit);
  }, [distanceUnit]);
  useEffect(() => {
    if (
      (paceMinutes || paceMinutes === 0) &&
      (paceSeconds || paceSeconds === 0)
    ) {
      const totalSeconds = getTotalSeconds(0, paceMinutes, paceSeconds);
      const newSeconds =
        totalSeconds / convertToUnits(1, prevPaceUnit, paceUnit);
      const result = secondsToHoursMinutesSeconds(newSeconds);
      setPaceMinutes(result.minutes);
      setPaceSeconds(result.seconds);
      setPrevPaceUnit(paceUnit);
    }
  }, [paceUnit]);
  const calcTime = () => {
    if (
      distance &&
      (paceMinutes || paceMinutes === 0) &&
      (paceSeconds || paceSeconds === 0)
    ) {
      const totalPaceSeconds = getTotalSeconds(0, paceMinutes, paceSeconds);
      if (totalPaceSeconds) {
        const totalTime =
          convertToUnits(distance, distanceUnit, paceUnit) * totalPaceSeconds;
        if (!isNaN(totalTime)) {
          const timens = secondsToHoursMinutesSeconds(totalTime);
          setSeconds(timens.seconds);
          setMinutes(timens.minutes);
          setHours(timens.hours);
        }
      }
    }
  };
  const calcDistance = () => {
    if (
      (hours || hours === 0) &&
      (minutes || minutes === 0) &&
      (seconds || seconds === 0) &&
      (paceMinutes || paceMinutes === 0) &&
      (paceSeconds || paceSeconds === 0)
    ) {
      const totalSeconds = getTotalSeconds(hours, minutes, seconds);
      const totalPaceSeconds = getTotalSeconds(0, paceMinutes, paceSeconds);
      if (totalSeconds && totalPaceSeconds) {
        const totalDistance = totalSeconds / totalPaceSeconds;
        if (!isNaN(totalDistance)) {
          setDistance(convertToUnits(totalDistance, paceUnit, distanceUnit));
        }
      }
    }
  };
  const calcPace = () => {
    if (
      (hours || hours === 0) &&
      (minutes || minutes === 0) &&
      (seconds || seconds === 0) &&
      distance
    ) {
      const totalSeconds = getTotalSeconds(hours, minutes, seconds);
      if (totalSeconds > 0) {
        const secondsPer =
          totalSeconds / convertToUnits(distance, distanceUnit, paceUnit);
        const pacePer = secondsToHoursMinutesSeconds(secondsPer);
        if (!isNaN(pacePer.minutes) && !isNaN(pacePer.seconds)) {
          setPaceMinutes(pacePer.minutes);
          setPaceSeconds(pacePer.seconds);
        }
      }
    }
  };
  useEffect(() => {
    switch (autoCalc) {
      case "time": {
        calcTime();
        break;
      }
      case "distance": {
        calcDistance();
        break;
      }
      case "pace": {
        calcPace();
        break;
      }
      default: {
        break;
      }
    }
  }, [
    hours,
    minutes,
    seconds,
    distance,
    paceMinutes,
    paceSeconds,
    distanceUnit,
    paceUnit,
    autoCalc
  ]);
  return (
    <div
      className="App"
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        width: 500,
        maxWidth: "100%",
        padding: 24,
        margin: "auto",
        boxSizing: "border-box",
        background: theme.background || "#08294d",
        transform: `scale(${scale})`,
        transformOrigin: "top center"
      }}
    >
      <style>
        {`html, body {
          background: ${theme.background || "#08294d"};
        }`}
      </style>
      <header className="App-header" style={{ minHeight: 0, width: "100%" }}>
        <h1
          style={{
            textAlign: "left",
            padding: 0,
            width: "100%",
            marginBottom: 0
          }}
        >
          <span>
            {theme.showLogo && (
              <div>
                YEAR IN <span style={{ color: "#F05537" }}>RUN</span>NING
                <br />
              </div>
            )}
            Pace Calculator
          </span>
        </h1>
      </header>
      <div
        style={{
          fontFamily: "Open Sans",
          textAlign: "left",
          width: "100%"
        }}
      >
        <p>
          A runner's pace calculator to figure out distance, pace, and time. Can
          also be used as a pace converter.
          <br />
          <em>Fill in two values to calculate the third.</em>
        </p>
      </div>
      <div
        style={{
          fontFamily: "Open Sans",
          marginTop: -28
        }}
      >
        <form noValidate autoComplete="off" style={{ width: "100%" }}>
          <div style={{ display: "flex", width: "100%" }}>
            <FormControl component="fieldset" style={{ display: "none" }}>
              <FormLabel component="legend">Calculate</FormLabel>
              <RadioGroup
                aria-label="gender"
                name="calulate"
                value={autoCalc}
                onChange={(e) => setAutoCalc(e.target.value)}
              >
                <FormControlLabel
                  value="time"
                  control={<Radio />}
                  label="Time"
                  labelPlacement="top"
                  style={{ marginTop: 24 - 3 }}
                />
                <FormControlLabel
                  value="distance"
                  control={<Radio />}
                  label="Distance"
                  labelPlacement="top"
                  style={{ marginTop: 24 - 3 }}
                />
                <FormControlLabel
                  value="pace"
                  control={<Radio />}
                  label="Pace"
                  labelPlacement="top"
                  style={{ marginTop: 24 - 3 }}
                />
              </RadioGroup>
            </FormControl>
            <div style={{ paddingTop: 16 }}>
              <div style={{ display: "flex", marginTop: 24 }}>
                <Styled.TimeTextFieldContainer>
                  <Styled.TimeTextField
                    id="hours"
                    label="Hours"
                    placeholder="00"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onFocus={(e) => e.target.select()}
                    value={pad(hours)}
                    onChange={(e) => setHours(getInt(e.target.value, 0, 999))}
                  />
                </Styled.TimeTextFieldContainer>
                <Styled.TimeTextFieldContainer>
                  <Styled.TimeTextField
                    id="minutes"
                    label="Minutes"
                    placeholder="00"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onFocus={(e) => e.target.select()}
                    value={pad(minutes)}
                    onChange={(e) => setMinutes(getInt(e.target.value, 0, 59))}
                  />
                </Styled.TimeTextFieldContainer>
                <Styled.TimeTextFieldContainer className="seconds">
                  <Styled.TimeTextField
                    id="seconds"
                    label="Seconds"
                    placeholder="00"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={seconds ? pad(seconds) : 0.0}
                    onFocus={(e) => e.target.select()}
                    onBlur={() => {
                      setSeconds(floatPad(parseFloat(seconds)));
                    }}
                    onChange={(e) => {
                      const newSeconds = parseFloat(e.target.value);
                      if (
                        e.target.value === "." ||
                        e.target.value === "" ||
                        newSeconds >= 0
                      ) {
                        setSeconds(e.target.value);
                      }
                    }}
                  />
                </Styled.TimeTextFieldContainer>
              </div>
              <div style={{ display: "flex", marginTop: 24 }}>
                <Styled.DistanceTextFieldContainer>
                  <Styled.DistanceTextField
                    id="outlined-basic"
                    label="Distance"
                    placeholder="0.0"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={parseFloat(distance) === 0 ? undefined : distance}
                    onFocus={(e) => e.target.select()}
                    onBlur={() => {
                      setDistance(floatPad(parseFloat(distance)));
                    }}
                    onChange={(e) => {
                      const newDistance = parseFloat(e.target.value);
                      if (
                        e.target.value === "." ||
                        e.target.value === "" ||
                        newDistance >= 0
                      ) {
                        setDistance(e.target.value);
                      }
                    }}
                  />
                </Styled.DistanceTextFieldContainer>
                <div style={{ flexGrow: 2, height: 20 }}>
                  <FormControl variant="" fullWidth>
                    <Styled.Select
                      aria-label="units"
                      labelId="distance-selection-label"
                      id="distance-selection"
                      value={distanceUnit}
                      onChange={(e) => {
                        setDistanceUnit(e.target.value);
                      }}
                      label="Units"
                    >
                      <MenuItem value={"mi"}>Miles</MenuItem>
                      <MenuItem value={"km"}>Kilometers</MenuItem>
                      <MenuItem value={"m"}>Meters</MenuItem>
                      <MenuItem value={"y"}>Yards</MenuItem>
                      <MenuItem value={"smoot"}>Smoots</MenuItem>
                    </Styled.Select>
                  </FormControl>
                  <FormControl variant="" fullWidth>
                    <Styled.Select
                      aria-label="units"
                      labelId="distance-selection-label"
                      id="distance-selection"
                      value={
                        possibleEventDistancesKm.find(({ label, km }) => {
                          return (
                            Math.abs(
                              convertToUnits(distance, distanceUnit, "km") - km
                            ) < 0.05
                          );
                        })?.km || "edistance"
                      }
                      onChange={(e) => {
                        setDistance(
                          convertToUnits(e.target.value, "km", distanceUnit)
                        );
                      }}
                      label="Units"
                    >
                      <MenuItem value={"edistance"}>Event</MenuItem>
                      {possibleEventDistancesKm.map(({ label, km }) => {
                        return <MenuItem value={km}>{label}</MenuItem>;
                      })}
                    </Styled.Select>
                  </FormControl>
                </div>
              </div>
              <div>
                <div style={{ display: "flex", marginTop: 24 }}>
                  <Styled.PaceTextFieldContainer>
                    <Styled.PaceTextField
                      id="minutes"
                      label="Minutes"
                      placeholder="00"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      onFocus={(e) => e.target.select()}
                      value={pad(paceMinutes)}
                      onChange={(e) =>
                        setPaceMinutes(getInt(e.target.value, 0, 59))
                      }
                    />
                  </Styled.PaceTextFieldContainer>
                  <Styled.PaceTextFieldContainer className="seconds">
                    <Styled.PaceTextField
                      id="seconds"
                      label="Seconds"
                      placeholder="00"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={paceSeconds ? paceSeconds : 0.0}
                      onFocus={(e) => e.target.select()}
                      onBlur={() => {
                        setPaceSeconds(floatPad(parseFloat(paceSeconds)));
                      }}
                      onChange={(e) => {
                        const newPaceSeconds = parseFloat(e.target.value);
                        if (
                          e.target.value === "." ||
                          e.target.value === "" ||
                          newPaceSeconds >= 0
                        ) {
                          setPaceSeconds(e.target.value);
                        }
                      }}
                    />
                  </Styled.PaceTextFieldContainer>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      paddingBottom: 2,
                      paddingLeft: 8
                    }}
                  >
                    <div
                      style={{
                        textAlign: "left",
                        fontStyle: "italic",
                        fontSize: 12
                      }}
                    ></div>

                    <FormControl variant="" fullWidth>
                      <Styled.Select
                        aria-label="units"
                        labelId="pace-unit-selection-label"
                        id="pace-unit-selection"
                        value={paceUnit}
                        onChange={(e) => {
                          setPaceUnit(e.target.value);
                        }}
                        label="Units"
                      >
                        <MenuItem value={"mi"}>per mi</MenuItem>
                        <MenuItem value={"km"}>per km</MenuItem>
                        <MenuItem value={"m"}>per meters</MenuItem>
                        <MenuItem value={"1500m"}>per 1500m</MenuItem>
                        <MenuItem value={"800m"}>per 800m</MenuItem>
                        <MenuItem value={"400m"}>per 400m</MenuItem>
                        <MenuItem value={"200m"}>per 200m</MenuItem>
                        <MenuItem value={"100m"}>per 100m</MenuItem>
                        <MenuItem value={"y"}>per yard</MenuItem>
                        <MenuItem value={"880y"}>per 880 yards</MenuItem>
                        <MenuItem value={"440y"}>per 440 yards</MenuItem>
                        <MenuItem value={"220y"}>per 220 yards</MenuItem>
                        <MenuItem value={"smoot"}>per smoot</MenuItem>
                      </Styled.Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                marginLeft: 16
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                style={{ marginTop: 42 }}
                onClick={calcTime}
              >
                Calculate
              </Button>
              <Button
                variant="contained"
                color="secondary"
                style={{ marginTop: 42 }}
                onClick={calcDistance}
              >
                Calculate
              </Button>
              <Button
                variant="contained"
                color="secondary"
                style={{ marginTop: 42 }}
                onClick={calcPace}
              >
                Calculate
              </Button>
            </div>
          </div>
        </form>
      </div>
      <div
        style={{
          fontFamily: "Open Sans",
          padding: "16px 32px",
          fontSize: "italic",
          marginTop: 16
        }}
      >
        Copy the code snippet below to embed the <br />
        <a
          href="https://yearinrunning.com/"
          style={{ color: theme.secondary || "#FFF" }}
        >
          Year In Running
        </a>{" "}
        Pace Calculator in your site!
        <div
          style={{
            marginTop: 8
          }}
        >
          <input
            onFocus={(e) => e.target.select()}
            style={{ width: "100%", height: 40, padding: "8px 8px" }}
            value={`<iframe src="https://app.yearinrunning.com/pace-calculator" title="Year In Running Pace Calculator" width="420" height="700" style="border:none;"></iframe>`}
          />
        </div>
        <div style={{ marginTop: 4 }}>
          Want custom branding?
          <br />
          Reach out{" "}
          <a
            href="https://www.instagram.com/yearinrunning/"
            style={{ color: theme.secondary || "#FFF" }}
          >
            @yearinrunning
          </a>
        </div>
      </div>
    </div>
  );
};

export default () => (
  <ThemeProvider theme={muiTheme}>
    <div className="App">
      <CssBaseline />
      <Calculator />
    </div>
  </ThemeProvider>
);
