const athletes = [
  {
    name: "Gordy Ainsleigh",
    gender: "M",
    age: 74,
    city: "Meadow Vista",
    state: "CA",
    country: "USA",
    bib: "0",
    entryType: "Pioneer",
    wsFinishes: 23
  },
  {
    name: "Clare Gallagher",
    gender: "F",
    age: 29,
    city: "Boulder",
    state: "CO",
    country: "USA",
    bib: "F1",
    entryType: "Top Ten",
    wsFinishes: 1
  },
  {
    name: "Brittany Peterson",
    gender: "F",
    age: 35,
    city: "Pocatello",
    state: "ID",
    country: "USA",
    bib: "F2",
    entryType: "Top Ten",
    wsFinishes: 1
  },
  {
    name: "Kaci Lickteig",
    gender: "F",
    age: 34,
    city: "Omaha",
    state: "NE",
    country: "USA",
    bib: "F3",
    entryType: "Top Ten",
    wsFinishes: 6
  },
  {
    name: "Beth Pascall",
    gender: "F",
    age: 33,
    city: "Belper",
    state: "GBR",
    country: "GBR",
    bib: "F4",
    entryType: "Top Ten",
    wsFinishes: 1
  },
  {
    name: "Camelia Mayfield",
    gender: "F",
    age: 28,
    city: "Bend",
    state: "OR",
    country: "USA",
    bib: "F5",
    entryType: "Top Ten",
    wsFinishes: 2
  },
  {
    name: "Nicole Bitter",
    gender: "F",
    age: 39,
    city: "Phoenix",
    state: "AZ",
    country: "USA",
    bib: "F7",
    entryType: "Top Ten",
    wsFinishes: 4
  },
  {
    name: "Kathryn Drew",
    gender: "F",
    age: 34,
    city: "Vancouver",
    state: "BC",
    country: "CAN",
    bib: "F8",
    entryType: "Top Ten",
    wsFinishes: 1
  },
  {
    name: "Addie Bracy",
    gender: "F",
    age: 34,
    city: "Denver",
    state: "CO",
    country: "USA",
    bib: "F9",
    entryType: "Top Ten",
    wsFinishes: 1
  },
  {
    name: "Jim Walmsley",
    gender: "M",
    age: 31,
    city: "Flagstaff",
    state: "AZ",
    country: "USA",
    bib: "M1",
    entryType: "Sponsor",
    wsFinishes: 3
  },
  {
    name: "Jared Hazen",
    gender: "M",
    age: 26,
    city: "Flagstaff",
    state: "AZ",
    country: "USA",
    bib: "M2",
    entryType: "Top Ten",
    wsFinishes: 3
  },
  {
    name: "Matt Daniels",
    gender: "M",
    age: 33,
    city: "Monument",
    state: "CO",
    country: "USA",
    bib: "M4",
    entryType: "Top Ten",
    wsFinishes: 1
  },
  {
    name: "Mark Hammond",
    gender: "M",
    age: 36,
    city: "Cottonwood Heights",
    state: "UT",
    country: "USA",
    bib: "M5",
    entryType: "Top Ten",
    wsFinishes: 3
  },
  {
    name: "Stephen Kersh",
    gender: "M",
    age: 29,
    city: "FLAGSTAFF",
    state: "AZ",
    country: "USA",
    bib: "M7",
    entryType: "Top Ten",
    wsFinishes: 1
  },
  {
    name: "Patrick Reagan",
    gender: "M",
    age: 34,
    city: "Savannah",
    state: "GA",
    country: "USA",
    bib: "M8",
    entryType: "Top Ten",
    wsFinishes: 1
  },
  {
    name: "Jeff Browning",
    gender: "M",
    age: 49,
    city: "Bozeman",
    state: "MT",
    country: "USA",
    bib: "M9",
    entryType: "Top Ten",
    wsFinishes: 5
  },
  {
    name: "Kyle Pietari",
    gender: "M",
    age: 34,
    city: "Edgewater",
    state: "CO",
    country: "USA",
    bib: "M10",
    entryType: "Top Ten",
    wsFinishes: 4
  },
  {
    name: "Ragna Debats",
    gender: "F",
    age: 42,
    city: "Matadepera",
    state: "ESP",
    country: "ESP",
    bib: "11",
    entryType: "UTWT",
    wsFinishes: 0
  },
  {
    name: "Ruth,Croft",
    gender: "F",
    age: 32,
    city: "Stillwater, Greymouth",
    state: "NZL",
    country: "NZL",
    bib: "13",
    entryType: "UTWT",
    wsFinishes: 0
  },
  {
    name: "Hayden Hawks",
    gender: "M",
    age: 30,
    city: "Cedar City",
    state: "UT",
    country: "USA",
    bib: "14",
    entryType: "UTWT",
    wsFinishes: 0
  },
  {
    name: "Keely Henninger",
    gender: "F",
    age: 29,
    city: "Portland",
    state: "OR",
    country: "USA",
    bib: "15",
    entryType: "UTWT",
    wsFinishes: 0
  },
  {
    name: "Camille Herron",
    gender: "F",
    age: 39,
    city: "Alamosa",
    state: "CO",
    country: "USA",
    bib: "16",
    entryType: "UTWT",
    wsFinishes: 0
  },
  {
    name: "Audrey Tanguy",
    gender: "F",
    age: 33,
    city: "Saint Pierre d'Albigny",
    state: "FRA",
    country: "FRA",
    bib: "19",
    entryType: "UTWT",
    wsFinishes: 0
  },
  {
    name: "Tim Tollefson",
    gender: "M",
    age: 36,
    city: "Mammoth Lakes",
    state: "CA",
    country: "USA",
    bib: "20",
    entryType: "UTWT",
    wsFinishes: 0
  },
  {
    name: "Michelle Magagna",
    gender: "F",
    age: 30,
    city: "Portage",
    state: "MI",
    country: "USA",
    bib: "22",
    entryType: "Golden Ticket",
    wsFinishes: 0
  },
  {
    name: "Drew Holmen",
    gender: "M",
    age: 29,
    city: "Boulder",
    state: "CO",
    country: "USA",
    bib: "23",
    entryType: "Golden Ticket",
    wsFinishes: 0
  },
  {
    name: "Alex Nichols",
    gender: "M",
    age: 36,
    city: "Colorado Springs",
    state: "CO",
    country: "USA",
    bib: "24",
    entryType: "Golden Ticket",
    wsFinishes: 1
  },
  {
    name: "Charlie Ware",
    gender: "M",
    age: 35,
    city: "Tucson",
    state: "AZ",
    country: "USA",
    bib: "27",
    entryType: "Golden Ticket",
    wsFinishes: 3
  },
  {
    name: "Elliot Cardin",
    gender: "M",
    age: 28,
    city: "Bromont",
    state: "QC",
    country: "CAN",
    bib: "28",
    entryType: "Golden Ticket",
    wsFinishes: 0
  },
  {
    name: "Katie Asmuth",
    gender: "F",
    age: 35,
    city: "Culver City",
    state: "CA",
    country: "USA",
    bib: "29",
    entryType: "Golden Ticket",
    wsFinishes: 0
  },
  {
    name: "Erin Clark",
    gender: "F",
    age: 26,
    city: "Missoula",
    state: "MT",
    country: "USA",
    bib: "30",
    entryType: "Golden Ticket",
    wsFinishes: 0
  },
  {
    name: "Ryan Miller",
    gender: "M",
    age: 29,
    city: "San Antonio",
    state: "TX",
    country: "USA",
    bib: "31",
    entryType: "Golden Ticket",
    wsFinishes: 0
  },
  {
    name: "Cody Lind",
    gender: "M",
    age: 26,
    city: "Challis",
    state: "ID",
    country: "USA",
    bib: "32",
    entryType: "Golden Ticket",
    wsFinishes: 0
  },
  {
    name: "Sarah Keyes",
    gender: "F",
    age: 36,
    city: "Saranac lake",
    state: "NY",
    country: "USA",
    bib: "33",
    entryType: "Golden Ticket",
    wsFinishes: 1
  },
  {
    name: "Mireya Vargas",
    gender: "F",
    age: 36,
    city: "Pasadena",
    state: "CA",
    country: "USA",
    bib: "34",
    entryType: "Golden Ticket",
    wsFinishes: 0
  },
  {
    name: "Tyler Green",
    gender: "M",
    age: 37,
    city: "Portland",
    state: "OR",
    country: "USA",
    bib: "35",
    entryType: "Golden Ticket",
    wsFinishes: 1
  },
  {
    name: "Eric Senseman",
    gender: "M",
    age: 32,
    city: "Flagstaff",
    state: "AZ",
    country: "USA",
    bib: "36",
    entryType: "Golden Ticket",
    wsFinishes: 1
  },
  {
    name: "Abby Hall",
    gender: "F",
    age: 30,
    city: "Boulder",
    state: "CO",
    country: "USA",
    bib: "37",
    entryType: "Golden Ticket",
    wsFinishes: 0
  },
  {
    name: "Emily Hawgood",
    gender: "F",
    age: 26,
    city: "Beatrice",
    state: "ZWE",
    country: "ZWE",
    bib: "38",
    entryType: "Golden Ticket",
    wsFinishes: 0
  },
  {
    name: "Max King",
    gender: "M",
    age: 41,
    city: "Bend",
    state: "OR",
    country: "USA",
    bib: "40",
    entryType: "Golden Ticket",
    wsFinishes: 1
  },
  {
    name: "Jorge Maravilla",
    gender: "M",
    age: 43,
    city: "Mill Valley",
    state: "CA",
    country: "USA",
    bib: "41",
    entryType: "Sponsor",
    wsFinishes: 3
  },
  {
    name: "Magdalena Boulet",
    gender: "F",
    age: 47,
    city: "Oakland",
    state: "CA",
    country: "USA",
    bib: "42",
    entryType: "Sponsor",
    wsFinishes: 2
  },
  {
    name: "Scott Wolfe",
    gender: "M",
    age: 47,
    city: "Bend",
    state: "OR",
    country: "USA",
    bib: "47",
    entryType: "Race Admin",
    wsFinishes: 3
  },
  {
    name: "Tom Afschar",
    gender: "M",
    age: 50,
    city: "South Pasadena",
    state: "CA",
    country: "USA",
    bib: "50",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Timothy Christoni",
    gender: "M",
    age: 52,
    city: "La Crescenta",
    state: "CA",
    country: "USA",
    bib: "52",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Shannon Howell",
    gender: "F",
    age: 42,
    city: "Simpsonville",
    state: "SC",
    country: "USA",
    bib: "53",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Verity Breen",
    gender: "F",
    age: 54,
    city: "Nicasio",
    state: "CA",
    country: "USA",
    bib: "54",
    entryType: "Raffle",
    wsFinishes: 0
  },
  {
    name: "Dan Barger",
    gender: "M",
    age: 55,
    city: "Steamboat Springs",
    state: "CO",
    country: "USA",
    bib: "55",
    entryType: "Race Admin",
    wsFinishes: 12
  },
  {
    name: "John Nichols",
    gender: "M",
    age: 56,
    city: "Sacramento",
    state: "CA",
    country: "USA",
    bib: "56",
    entryType: "Raffle",
    wsFinishes: 11
  },
  {
    name: "Joe Steinmetz",
    gender: "M",
    age: 57,
    city: "Loomis",
    state: "CA",
    country: "USA",
    bib: "57",
    entryType: "Race Admin",
    wsFinishes: 0
  },
  {
    name: "John Trent",
    gender: "M",
    age: 58,
    city: "Reno",
    state: "NV",
    country: "USA",
    bib: "58",
    entryType: "BOD",
    wsFinishes: 10
  },
  {
    name: "Mike Acer",
    gender: "M",
    age: 59,
    city: "Yardley",
    state: "PA",
    country: "USA",
    bib: "59",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Paulo Aguilar",
    gender: "M",
    age: 39,
    city: "Encino",
    state: "CA",
    country: "USA",
    bib: "61",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Kenneth Gregorich",
    gender: "M",
    age: 62,
    city: "Bishop",
    state: "CA",
    country: "USA",
    bib: "62",
    entryType: "Silver Legend",
    wsFinishes: 11
  },
  {
    name: "Donn Zea",
    gender: "M",
    age: 63,
    city: "Grass Valley",
    state: "CA",
    country: "USA",
    bib: "63",
    entryType: "BOD",
    wsFinishes: 6
  },
  {
    name: "Carroll Bush",
    gender: "M",
    age: 56,
    city: "ORANGEVALE",
    state: "CA",
    country: "USA",
    bib: "64",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Karen Bonnett-Natraj",
    gender: "F",
    age: 65,
    city: "Auburn",
    state: "CA",
    country: "USA",
    bib: "65",
    entryType: "Bonus Lottery",
    wsFinishes: 3
  },
  {
    name: "Scott Howell",
    gender: "M",
    age: 43,
    city: "Longmont",
    state: "CO",
    country: "USA",
    bib: "66",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Kara Teklinski",
    gender: "F",
    age: 47,
    city: "Mill Valley",
    state: "CA",
    country: "USA",
    bib: "68",
    entryType: "Race Admin",
    wsFinishes: 0
  },
  {
    name: "Aaron Ellison",
    gender: "M",
    age: 45,
    city: "Ellicott City",
    state: "MD",
    country: "USA",
    bib: "69",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Joshua Holer",
    gender: "M",
    age: 45,
    city: "Nathrop",
    state: "CO",
    country: "USA",
    bib: "70",
    entryType: "Raffle",
    wsFinishes: 0
  },
  {
    name: "David Aguayo",
    gender: "M",
    age: 45,
    city: "San Luis Obispo",
    state: "CA",
    country: "USA",
    bib: "71",
    entryType: "Lottery (1 ticket)",
    wsFinishes: 1
  },
  {
    name: "Steve Osborne",
    gender: "M",
    age: 47,
    city: "San Ramon",
    state: "CA",
    country: "USA",
    bib: "73",
    entryType: "Lottery (64 tickets)",
    wsFinishes: 0
  },
  {
    name: "Jack Hsueh",
    gender: "M",
    age: 45,
    city: "Kensington",
    state: "CA",
    country: "USA",
    bib: "74",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Jim McCaffrey",
    gender: "M",
    age: 44,
    city: "Hamden",
    state: "CT",
    country: "USA",
    bib: "76",
    entryType: "Lottery (64 tickets)",
    wsFinishes: 0
  },
  {
    name: "Huy Le",
    gender: "F",
    age: 49,
    city: "Redlands",
    state: "CA",
    country: "USA",
    bib: "78",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Michael Alfred",
    gender: "M",
    age: 40,
    city: "Denver",
    state: "CO",
    country: "USA",
    bib: "79",
    entryType: "Lottery (1 ticket)",
    wsFinishes: 0
  },
  {
    name: "Cody Draper",
    gender: "M",
    age: 41,
    city: "Smithfield",
    state: "UT",
    country: "USA",
    bib: "80",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Nick Affleck",
    gender: "M",
    age: 40,
    city: "Chico",
    state: "CA",
    country: "USA",
    bib: "81",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Cheryl Anderson",
    gender: "F",
    age: 48,
    city: "Saline",
    state: "MI",
    country: "USA",
    bib: "82",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Adam Aucella",
    gender: "M",
    age: 42,
    city: "Nevada City",
    state: "CA",
    country: "USA",
    bib: "84",
    entryType: "Raffle",
    wsFinishes: 0
  },
  {
    name: "Brett Sherfy",
    gender: "M",
    age: 33,
    city: "Blacksburg",
    state: "VA",
    country: "USA",
    bib: "87",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Victor Yang",
    gender: "M",
    age: 45,
    city: "Palos Verdes estates",
    state: "CA",
    country: "USA",
    bib: "88",
    entryType: "Sponsor",
    wsFinishes: 0
  },
  {
    name: "Jack Bailey",
    gender: "M",
    age: 42,
    city: "Medway",
    state: "MA",
    country: "USA",
    bib: "90",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Kelly Barber",
    gender: "M",
    age: 51,
    city: "Woodland",
    state: "CA",
    country: "USA",
    bib: "91",
    entryType: "Lottery (1 ticket)",
    wsFinishes: 3
  },
  {
    name: "Katie Trent",
    gender: "F",
    age: 28,
    city: "Reno",
    state: "NV",
    country: "USA",
    bib: "92",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 1
  },
  {
    name: "Michael Fink",
    gender: "M",
    age: 52,
    city: "Lincoln",
    state: "CA",
    country: "USA",
    bib: "93",
    entryType: "Bonus Lottery",
    wsFinishes: 1
  },
  {
    name: "Luke Bateman",
    gender: "M",
    age: 35,
    city: "Dallas",
    state: "TX",
    country: "USA",
    bib: "95",
    entryType: "Sponsor",
    wsFinishes: 0
  },
  {
    name: "Redfield Baum",
    gender: "M",
    age: 48,
    city: "Scottsdale",
    state: "AZ",
    country: "USA",
    bib: "96",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Drew Bennett",
    gender: "M",
    age: 57,
    city: "Northville",
    state: "MI",
    country: "USA",
    bib: "97",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Jeanne Bennett",
    gender: "F",
    age: 46,
    city: "Lewisville",
    state: "NC",
    country: "USA",
    bib: "98",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Sarah Greenlee",
    gender: "F",
    age: 36,
    city: "San Diego",
    state: "CA",
    country: "USA",
    bib: "99",
    entryType: "Lottery (2 tickets)",
    wsFinishes: 0
  },
  {
    name: "Philip Sanderson",
    gender: "M",
    age: 53,
    city: "Mill Valley",
    state: "CA",
    country: "USA",
    bib: "100",
    entryType: "Sponsor",
    wsFinishes: 6
  },
  {
    name: "Drew Bernhisel",
    gender: "M",
    age: 29,
    city: "El Paso",
    state: "TX",
    country: "USA",
    bib: "102",
    entryType: "Lottery (2 tickets)",
    wsFinishes: 0
  },
  {
    name: "Josh Bonkowsky",
    gender: "M",
    age: 51,
    city: "Salt Lake City",
    state: "UT",
    country: "USA",
    bib: "103",
    entryType: "Lottery (1 ticket)",
    wsFinishes: 0
  },
  {
    name: "Paul Bonnett",
    gender: "M",
    age: 59,
    city: "Phoenix",
    state: "AZ",
    country: "USA",
    bib: "104",
    entryType: "Lottery (2 tickets)",
    wsFinishes: 4
  },
  {
    name: "Debbie Booth",
    gender: "F",
    age: 48,
    city: "Auburn",
    state: "CA",
    country: "USA",
    bib: "105",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Will Briggs",
    gender: "M",
    age: 32,
    city: "Poway",
    state: "CA",
    country: "USA",
    bib: "106",
    entryType: "Lottery (1 ticket)",
    wsFinishes: 0
  },
  {
    name: "Kevin Doty",
    gender: "M",
    age: 42,
    city: "Longmont",
    state: "CO",
    country: "USA",
    bib: "108",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Stacey Buckley",
    gender: "F",
    age: 45,
    city: "Omaha",
    state: "NE",
    country: "USA",
    bib: "109",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Travis Burmaster",
    gender: "M",
    age: 47,
    city: "Broomfield",
    state: "CO",
    country: "USA",
    bib: "110",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Dan Balestrieri",
    gender: "M",
    age: 41,
    city: "Billerica",
    state: "MA",
    country: "USA",
    bib: "111",
    entryType: "Raffle",
    wsFinishes: 0
  },
  {
    name: "Matt Campbell",
    gender: "M",
    age: 37,
    city: "Bothell",
    state: "WA",
    country: "USA",
    bib: "112",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 1
  },
  {
    name: "Robb Gushiken",
    gender: "M",
    age: 42,
    city: "Carabietta",
    state: "CHE",
    country: "CHE",
    bib: "113",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Jesse Canfield",
    gender: "M",
    age: 42,
    city: "Apalachin",
    state: "NY",
    country: "USA",
    bib: "114",
    entryType: "Lottery (1 ticket)",
    wsFinishes: 0
  },
  {
    name: "Michael Stegura",
    gender: "M",
    age: 30,
    city: "Philadelphia",
    state: "PA",
    country: "USA",
    bib: "115",
    entryType: "Lottery (1 ticket)",
    wsFinishes: 0
  },
  {
    name: "Grant Carboni",
    gender: "M",
    age: 50,
    city: "Sacramento",
    state: "CA",
    country: "USA",
    bib: "116",
    entryType: "Raffle",
    wsFinishes: 2
  },
  {
    name: "Ej Maldonado",
    gender: "M",
    age: 47,
    city: "Carson City",
    state: "NV",
    country: "USA",
    bib: "117",
    entryType: "Race Admin",
    wsFinishes: 0
  },
  {
    name: "Dwight Brown",
    gender: "M",
    age: 60,
    city: "Los Altos",
    state: "CA",
    country: "USA",
    bib: "118",
    entryType: "Race Admin",
    wsFinishes: 1
  },
  {
    name: "Richard Carney",
    gender: "M",
    age: 56,
    city: "Arcadia",
    state: "CA",
    country: "USA",
    bib: "119",
    entryType: "Lottery (2 tickets)",
    wsFinishes: 0
  },
  {
    name: "Donal Carroll",
    gender: "M",
    age: 54,
    city: "San Francisco",
    state: "CA",
    country: "USA",
    bib: "120",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Richard Coughlin",
    gender: "M",
    age: 47,
    city: "Charlotte",
    state: "NC",
    country: "USA",
    bib: "121",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Katherine Gifford",
    gender: "F",
    age: 46,
    city: "Sacramento",
    state: "CA",
    country: "USA",
    bib: "122",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 1
  },
  {
    name: "Joel Carson",
    gender: "M",
    age: 51,
    city: "Carmichael",
    state: "CA",
    country: "USA",
    bib: "124",
    entryType: "Bonus Lottery",
    wsFinishes: 0
  },
  {
    name: "Robert Cawood",
    gender: "M",
    age: 52,
    city: "Annapolis",
    state: "MD",
    country: "USA",
    bib: "125",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Michael Bunda",
    gender: "M",
    age: 40,
    city: "Saint Paul",
    state: "MN",
    country: "USA",
    bib: "126",
    entryType: "Lottery (64 tickets)",
    wsFinishes: 0
  },
  {
    name: "Michael Hooker",
    gender: "M",
    age: 53,
    city: "Anaheim",
    state: "CA",
    country: "USA",
    bib: "127",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Megan Cheng",
    gender: "F",
    age: 40,
    city: "San Francisco",
    state: "CA",
    country: "USA",
    bib: "129",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Joseph Chick",
    gender: "M",
    age: 44,
    city: "Ashland",
    state: "OR",
    country: "USA",
    bib: "130",
    entryType: "Race Admin",
    wsFinishes: 1
  },
  {
    name: "Matthew Clark",
    gender: "M",
    age: 53,
    city: "Highland",
    state: "UT",
    country: "USA",
    bib: "133",
    entryType: "Lottery (64 tickets)",
    wsFinishes: 0
  },
  {
    name: "Kris Clinard",
    gender: "M",
    age: 46,
    city: "Larkspur",
    state: "CA",
    country: "USA",
    bib: "134",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Jill Cole",
    gender: "F",
    age: 50,
    city: "Los Gatos",
    state: "CA",
    country: "USA",
    bib: "135",
    entryType: "Race Admin",
    wsFinishes: 0
  },
  {
    name: "Jeffrey Conner",
    gender: "M",
    age: 40,
    city: "Reno",
    state: "NV",
    country: "USA",
    bib: "136",
    entryType: "Race Admin",
    wsFinishes: 0
  },
  {
    name: "Sharleen Stevens",
    gender: "F",
    age: 38,
    city: "Sioux Falls",
    state: "SD",
    country: "USA",
    bib: "137",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Brent Crumley",
    gender: "M",
    age: 45,
    city: "Boise",
    state: "ID",
    country: "USA",
    bib: "138",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Joe Currens",
    gender: "M",
    age: 55,
    city: "Flower Mound",
    state: "TX",
    country: "USA",
    bib: "139",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Bruce Cyra",
    gender: "M",
    age: 63,
    city: "Vashon Island",
    state: "WA",
    country: "USA",
    bib: "140",
    entryType: "Lottery (64 tickets)",
    wsFinishes: 0
  },
  {
    name: "Satpal Dalal",
    gender: "M",
    age: 46,
    city: "Cupertino",
    state: "CA",
    country: "USA",
    bib: "142",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Michael Daly",
    gender: "M",
    age: 45,
    city: "San Diego",
    state: "CA",
    country: "USA",
    bib: "143",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 1
  },
  {
    name: "Katie Daman",
    gender: "F",
    age: 40,
    city: "Fife Lake",
    state: "MI",
    country: "USA",
    bib: "144",
    entryType: "Lottery (2 tickets)",
    wsFinishes: 0
  },
  {
    name: "Jim Daniels",
    gender: "M",
    age: 48,
    city: "Front Royal",
    state: "VA",
    country: "USA",
    bib: "145",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Anthony Chavez",
    gender: "M",
    age: 47,
    city: "Auburn",
    state: "CA",
    country: "USA",
    bib: "147",
    entryType: "Lottery (2 tickets)",
    wsFinishes: 1
  },
  {
    name: "Sam Fiandaca",
    gender: "M",
    age: 49,
    city: "Foresthill",
    state: "CA",
    country: "USA",
    bib: "148",
    entryType: "Sponsor",
    wsFinishes: 2
  },
  {
    name: "Jeff Deaton",
    gender: "M",
    age: 50,
    city: "Murfreesboro",
    state: "TN",
    country: "USA",
    bib: "149",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Timothy Felker",
    gender: "M",
    age: 50,
    city: "Antelope",
    state: "CA",
    country: "USA",
    bib: "150",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Joey Montoya",
    gender: "M",
    age: 42,
    city: "Auburn",
    state: "CA",
    country: "USA",
    bib: "151",
    entryType: "Race Admin",
    wsFinishes: 0
  },
  {
    name: "Claudine Roghi",
    gender: "F",
    age: 52,
    city: "West Melbourne",
    state: "FL",
    country: "USA",
    bib: "152",
    entryType: "Lottery (1 ticket)",
    wsFinishes: 0
  },
  {
    name: "Sean Dempsey",
    gender: "M",
    age: 48,
    city: "Los Altos",
    state: "CA",
    country: "USA",
    bib: "153",
    entryType: "Raffle",
    wsFinishes: 0
  },
  {
    name: "Shashank Deshpande",
    gender: "M",
    age: 56,
    city: "Folsom",
    state: "CA",
    country: "USA",
    bib: "154",
    entryType: "Lottery (2 tickets)",
    wsFinishes: 0
  },
  {
    name: "Justin Dew",
    gender: "M",
    age: 46,
    city: "New York",
    state: "NY",
    country: "USA",
    bib: "156",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Tracy Dimino",
    gender: "F",
    age: 50,
    city: "Ramona",
    state: "CA",
    country: "USA",
    bib: "158",
    entryType: "Raffle",
    wsFinishes: 0
  },
  {
    name: "Jason Dobson",
    gender: "M",
    age: 43,
    city: "Creswell",
    state: "OR",
    country: "USA",
    bib: "159",
    entryType: "Lottery (1 ticket)",
    wsFinishes: 0
  },
  {
    name: "Douglas Donovan",
    gender: "M",
    age: 51,
    city: "Reno",
    state: "NV",
    country: "USA",
    bib: "160",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 1
  },
  {
    name: "Daigo Echizenya",
    gender: "M",
    age: 52,
    city: "Dublin",
    state: "CA",
    country: "USA",
    bib: "161",
    entryType: "Lottery (128 tickets)",
    wsFinishes: 0
  },
  {
    name: "Klas Eklof",
    gender: "M",
    age: 50,
    city: "Oakland",
    state: "CA",
    country: "USA",
    bib: "162",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 4
  },
  {
    name: "Tanya Espalin",
    gender: "F",
    age: 40,
    city: "San Antonio",
    state: "TX",
    country: "USA",
    bib: "163",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Arya Farahani",
    gender: "M",
    age: 39,
    city: "Bellevue",
    state: "WA",
    country: "USA",
    bib: "165",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Clare Farrow",
    gender: "F",
    age: 27,
    city: "Lakewood",
    state: "CO",
    country: "USA",
    bib: "166",
    entryType: "Lottery (1 ticket)",
    wsFinishes: 0
  },
  {
    name: "David Files",
    gender: "M",
    age: 46,
    city: "Batavia",
    state: "IL",
    country: "USA",
    bib: "167",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 1
  },
  {
    name: "Jonathan Fletcher",
    gender: "M",
    age: 38,
    city: "Fayetteville",
    state: "NC",
    country: "USA",
    bib: "168",
    entryType: "Sponsor",
    wsFinishes: 0
  },
  {
    name: "Steven Wright",
    gender: "M",
    age: 62,
    city: "Stockton",
    state: "CA",
    country: "USA",
    bib: "169",
    entryType: "Lottery (64 tickets)",
    wsFinishes: 0
  },
  {
    name: "Jaclyn Foroughi",
    gender: "F",
    age: 41,
    city: "Truckee",
    state: "CA",
    country: "USA",
    bib: "171",
    entryType: "Raffle",
    wsFinishes: 0
  },
  {
    name: "Jenny Fox",
    gender: "F",
    age: 41,
    city: "Steamboat Springs",
    state: "CO",
    country: "USA",
    bib: "172",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Douglas Wickert",
    gender: "M",
    age: 48,
    city: "Monument",
    state: "CO",
    country: "USA",
    bib: "173",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 1
  },
  {
    name: "Mark Frey",
    gender: "M",
    age: 41,
    city: "Pittsburgh",
    state: "PA",
    country: "USA",
    bib: "174",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Veronica Fujisawa",
    gender: "F",
    age: 51,
    city: "Pleasant Hill",
    state: "CA",
    country: "USA",
    bib: "175",
    entryType: "Race Admin",
    wsFinishes: 0
  },
  {
    name: "Joseph Gallitano",
    gender: "M",
    age: 39,
    city: "Philadelphia",
    state: "PA",
    country: "USA",
    bib: "177",
    entryType: "Lottery (1 ticket)",
    wsFinishes: 0
  },
  {
    name: "Steve Rowbury",
    gender: "M",
    age: 53,
    city: "Mill Valley",
    state: "CA",
    country: "USA",
    bib: "178",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Jorma Gates",
    gender: "M",
    age: 50,
    city: "Santa Rosa",
    state: "CA",
    country: "USA",
    bib: "179",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Keith Gates",
    gender: "M",
    age: 40,
    city: "Lawrenceville",
    state: "GA",
    country: "USA",
    bib: "180",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Josh Grant",
    gender: "M",
    age: 46,
    city: "Morgan Hill",
    state: "CA",
    country: "USA",
    bib: "184",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Brett Wilks",
    gender: "M",
    age: 41,
    city: "Huntsville",
    state: "AL",
    country: "USA",
    bib: "185",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Sean Grant",
    gender: "M",
    age: 35,
    city: "Novato",
    state: "CA",
    country: "USA",
    bib: "186",
    entryType: "Raffle",
    wsFinishes: 0
  },
  {
    name: "Calvin Wong",
    gender: "M",
    age: 39,
    city: "Redwood City",
    state: "CA",
    country: "USA",
    bib: "187",
    entryType: "Lottery (128 tickets)",
    wsFinishes: 2
  },
  {
    name: "Dan Fish",
    gender: "M",
    age: 49,
    city: "Newbury Park",
    state: "CA",
    country: "USA",
    bib: "188",
    entryType: "Lottery (1 ticket)",
    wsFinishes: 1
  },
  {
    name: "Jeremy Greene",
    gender: "M",
    age: 46,
    city: "Arlington",
    state: "WA",
    country: "USA",
    bib: "190",
    entryType: "Lottery (2 tickets)",
    wsFinishes: 0
  },
  {
    name: "Mark Gregware",
    gender: "M",
    age: 41,
    city: "Minneapolis",
    state: "MN",
    country: "USA",
    bib: "191",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Mike Grimm",
    gender: "M",
    age: 60,
    city: "Los Altos Hills",
    state: "CA",
    country: "USA",
    bib: "192",
    entryType: "Lottery (128 tickets)",
    wsFinishes: 1
  },
  {
    name: "Abram Haen",
    gender: "M",
    age: 39,
    city: "Truckee",
    state: "CA",
    country: "USA",
    bib: "193",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Breen Haire",
    gender: "M",
    age: 48,
    city: "Houston",
    state: "TX",
    country: "USA",
    bib: "194",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Tyler Hall",
    gender: "M",
    age: 48,
    city: "Morgan",
    state: "UT",
    country: "USA",
    bib: "195",
    entryType: "Lottery (64 tickets)",
    wsFinishes: 0
  },
  {
    name: "Neil Johnson",
    gender: "M",
    age: 44,
    city: "Golden Valley",
    state: "MN",
    country: "USA",
    bib: "196",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Tyson Hamilton",
    gender: "M",
    age: 42,
    city: "South Jordan",
    state: "UT",
    country: "USA",
    bib: "197",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Blane Clark",
    gender: "M",
    age: 48,
    city: "Franklin",
    state: "TN",
    country: "USA",
    bib: "200",
    entryType: "Lottery (2 tickets)",
    wsFinishes: 0
  },
  {
    name: "Lisa Wright",
    gender: "F",
    age: 60,
    city: "Liberty Hill",
    state: "TX",
    country: "USA",
    bib: "205",
    entryType: "Race Admin",
    wsFinishes: 0
  },
  {
    name: "Chris Chamberlin",
    gender: "M",
    age: 45,
    city: "Seattle",
    state: "WA",
    country: "USA",
    bib: "206",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "James Heun",
    gender: "M",
    age: 62,
    city: "Burton",
    state: "OH",
    country: "USA",
    bib: "207",
    entryType: "Lottery (64 tickets)",
    wsFinishes: 0
  },
  {
    name: "April Wilbur",
    gender: "F",
    age: 43,
    city: "Boise",
    state: "ID",
    country: "USA",
    bib: "208",
    entryType: "Lottery (1 ticket)",
    wsFinishes: 0
  },
  {
    name: "Nicholas Hobbs",
    gender: "M",
    age: 37,
    city: "Wake Forest",
    state: "NC",
    country: "USA",
    bib: "210",
    entryType: "Lottery (2 tickets)",
    wsFinishes: 0
  },
  {
    name: "Charles Hofacker",
    gender: "M",
    age: 56,
    city: "Carmichael",
    state: "CA",
    country: "USA",
    bib: "211",
    entryType: "Lottery (1 ticket)",
    wsFinishes: 1
  },
  {
    name: "David Buse",
    gender: "M",
    age: 44,
    city: "San Diego",
    state: "CA",
    country: "USA",
    bib: "212",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Brenda Hofer",
    gender: "F",
    age: 46,
    city: "Roseville",
    state: "CA",
    country: "USA",
    bib: "213",
    entryType: "Sponsor",
    wsFinishes: 0
  },
  {
    name: "Olga Huber",
    gender: "F",
    age: 50,
    city: "Macedon",
    state: "NY",
    country: "USA",
    bib: "215",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Scott Hudson",
    gender: "M",
    age: 53,
    city: "Carmel",
    state: "IN",
    country: "USA",
    bib: "216",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Larry Huffman",
    gender: "M",
    age: 60,
    city: "Arlington",
    state: "VA",
    country: "USA",
    bib: "217",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Josh Gum",
    gender: "M",
    age: 41,
    city: "Corvallis",
    state: "OR",
    country: "USA",
    bib: "218",
    entryType: "Lottery (2 tickets)",
    wsFinishes: 0
  },
  {
    name: "Sheri Poskanzer",
    gender: "F",
    age: 32,
    city: "Boise",
    state: "ID",
    country: "USA",
    bib: "219",
    entryType: "Raffle",
    wsFinishes: 0
  },
  {
    name: "Laura Matz",
    gender: "F",
    age: 35,
    city: "Auburn",
    state: "CA",
    country: "USA",
    bib: "222",
    entryType: "Race Admin",
    wsFinishes: 0
  },
  {
    name: "Alyson Jay",
    gender: "F",
    age: 42,
    city: "Flagstaff",
    state: "AZ",
    country: "USA",
    bib: "224",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 1
  },
  {
    name: "Tony Johns",
    gender: "M",
    age: 47,
    city: "Everett",
    state: "WA",
    country: "USA",
    bib: "227",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Brandon Johnson",
    gender: "M",
    age: 37,
    city: "Barnum",
    state: "MN",
    country: "USA",
    bib: "228",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Lisa Jones",
    gender: "F",
    age: 44,
    city: "Virginia Beach",
    state: "VA",
    country: "USA",
    bib: "230",
    entryType: "Lottery (2 tickets)",
    wsFinishes: 0
  },
  {
    name: "Wade Kao",
    gender: "M",
    age: 47,
    city: "Houston",
    state: "TX",
    country: "USA",
    bib: "231",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Paul Karr",
    gender: "M",
    age: 58,
    city: "Redwood City",
    state: "CA",
    country: "USA",
    bib: "232",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Joe Albert",
    gender: "M",
    age: 40,
    city: "Portland",
    state: "OR",
    country: "USA",
    bib: "234",
    entryType: "Lottery (2 tickets)",
    wsFinishes: 0
  },
  {
    name: "Victoria Kendra",
    gender: "F",
    age: 50,
    city: "Siler City",
    state: "NC",
    country: "USA",
    bib: "235",
    entryType: "Lottery (2 tickets)",
    wsFinishes: 0
  },
  {
    name: "John Kerschbaum",
    gender: "M",
    age: 46,
    city: "San Marcos",
    state: "CA",
    country: "USA",
    bib: "236",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Ken Kilar",
    gender: "M",
    age: 52,
    city: "Pasadena",
    state: "CA",
    country: "USA",
    bib: "237",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Joseph Kim",
    gender: "M",
    age: 38,
    city: "Los Angeles",
    state: "CA",
    country: "USA",
    bib: "238",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Eric King",
    gender: "M",
    age: 54,
    city: "Redwood City",
    state: "CA",
    country: "USA",
    bib: "239",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Peter Kleuser",
    gender: "M",
    age: 43,
    city: "Kingston Springs",
    state: "TN",
    country: "USA",
    bib: "240",
    entryType: "Lottery (64 tickets)",
    wsFinishes: 0
  },
  {
    name: "Phil Kochik",
    gender: "M",
    age: 43,
    city: "Seattle",
    state: "WA",
    country: "USA",
    bib: "241",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 3
  },
  {
    name: "Sean Lehman",
    gender: "M",
    age: 50,
    city: "Novato",
    state: "CA",
    country: "USA",
    bib: "242",
    entryType: "Raffle",
    wsFinishes: 0
  },
  {
    name: "Shaun Koehler",
    gender: "M",
    age: 42,
    city: "Wake forest",
    state: "NC",
    country: "USA",
    bib: "243",
    entryType: "Lottery (1 ticket)",
    wsFinishes: 0
  },
  {
    name: "Susan Kramer",
    gender: "F",
    age: 56,
    city: "Peoria",
    state: "AZ",
    country: "USA",
    bib: "244",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Shane Kroth",
    gender: "M",
    age: 49,
    city: "Portland",
    state: "OR",
    country: "USA",
    bib: "245",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 1
  },
  {
    name: "Stephen Kukta",
    gender: "M",
    age: 56,
    city: "Indian Wells",
    state: "CA",
    country: "USA",
    bib: "247",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Jeff Kulesza",
    gender: "M",
    age: 42,
    city: "HILLIARD",
    state: "OH",
    country: "USA",
    bib: "248",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Tony Nguyen",
    gender: "M",
    age: 47,
    city: "Antioch",
    state: "CA",
    country: "USA",
    bib: "250",
    entryType: "Race Admin",
    wsFinishes: 0
  },
  {
    name: "Kelly Lance",
    gender: "M",
    age: 53,
    city: "Pocatello",
    state: "ID",
    country: "USA",
    bib: "251",
    entryType: "Raffle",
    wsFinishes: 0
  },
  {
    name: "Scott Laplante",
    gender: "M",
    age: 48,
    city: "Tigard",
    state: "OR",
    country: "USA",
    bib: "252",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Alexander Larson",
    gender: "M",
    age: 43,
    city: "South Lake Tahoe",
    state: "CA",
    country: "USA",
    bib: "253",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "David Lee",
    gender: "M",
    age: 51,
    city: "Fullerton",
    state: "CA",
    country: "USA",
    bib: "255",
    entryType: "Lottery (2 tickets)",
    wsFinishes: 1
  },
  {
    name: "Jae Hoon Lee",
    gender: "M",
    age: 55,
    city: "La Crescenta",
    state: "CA",
    country: "USA",
    bib: "257",
    entryType: "Lottery (1 ticket)",
    wsFinishes: 0
  },
  {
    name: "Sudheer Maremanda",
    gender: "M",
    age: 44,
    city: "Bellevue",
    state: "WA",
    country: "USA",
    bib: "258",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Scott Lee",
    gender: "M",
    age: 50,
    city: "Haymarket",
    state: "VA",
    country: "USA",
    bib: "259",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Charley Jones",
    gender: "M",
    age: 49,
    city: "Folsom",
    state: "CA",
    country: "USA",
    bib: "260",
    entryType: "Raffle",
    wsFinishes: 12
  },
  {
    name: "Kate Leis",
    gender: "F",
    age: 44,
    city: "Delano",
    state: "MN",
    country: "USA",
    bib: "261",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Bill Dittman",
    gender: "M",
    age: 33,
    city: "Brookfield",
    state: "WI",
    country: "USA",
    bib: "262",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "David Lent",
    gender: "M",
    age: 58,
    city: "Folsom",
    state: "CA",
    country: "USA",
    bib: "263",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Keith Evans-Orville",
    gender: "M",
    age: 52,
    city: "Oakland",
    state: "CA",
    country: "USA",
    bib: "264",
    entryType: "Race Admin",
    wsFinishes: 0
  },
  {
    name: "Kenneth Letterle",
    gender: "M",
    age: 38,
    city: "Houston",
    state: "TX",
    country: "USA",
    bib: "266",
    entryType: "Lottery (64 tickets)",
    wsFinishes: 1
  },
  {
    name: "Loren Lewis",
    gender: "M",
    age: 49,
    city: "San Jose",
    state: "CA",
    country: "USA",
    bib: "267",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 1
  },
  {
    name: "Jeff Lewman",
    gender: "M",
    age: 51,
    city: "Bartlesville",
    state: "OK",
    country: "USA",
    bib: "269",
    entryType: "Lottery (2 tickets)",
    wsFinishes: 0
  },
  {
    name: "Dong Lin",
    gender: "M",
    age: 49,
    city: "West Nyack",
    state: "NY",
    country: "USA",
    bib: "270",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Matt Lowe",
    gender: "M",
    age: 40,
    city: "Hamilton",
    state: "ON",
    country: "CAN",
    bib: "271",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Carolyn Luckett",
    gender: "F",
    age: 54,
    city: "Olney",
    state: "MT",
    country: "USA",
    bib: "272",
    entryType: "Lottery (2 tickets)",
    wsFinishes: 2
  },
  {
    name: "Jonathan Lundy",
    gender: "M",
    age: 44,
    city: "Shepherdsville",
    state: "KY",
    country: "USA",
    bib: "273",
    entryType: "Lottery (128 tickets)",
    wsFinishes: 0
  },
  {
    name: "Jeffrey Lysiak",
    gender: "M",
    age: 57,
    city: "Crozet",
    state: "VA",
    country: "USA",
    bib: "274",
    entryType: "Lottery (64 tickets)",
    wsFinishes: 0
  },
  {
    name: "Aaron Maas",
    gender: "M",
    age: 40,
    city: "Alpharetta",
    state: "GA",
    country: "USA",
    bib: "275",
    entryType: "Lottery (64 tickets)",
    wsFinishes: 0
  },
  {
    name: "Zandy Mangold",
    gender: "M",
    age: 47,
    city: "Brooklyn",
    state: "NY",
    country: "USA",
    bib: "276",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Edward Marchini",
    gender: "M",
    age: 62,
    city: "Placerville",
    state: "CA",
    country: "USA",
    bib: "277",
    entryType: "Race Admin",
    wsFinishes: 0
  },
  {
    name: "Vincent Matteo",
    gender: "M",
    age: 53,
    city: "Cave Creek",
    state: "AZ",
    country: "USA",
    bib: "278",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Christopher McBride",
    gender: "M",
    age: 51,
    city: "Golden",
    state: "CO",
    country: "USA",
    bib: "279",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Rob Mccool",
    gender: "M",
    age: 42,
    city: "Redondo Beach",
    state: "CA",
    country: "USA",
    bib: "280",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Robin Mcdearmon",
    gender: "M",
    age: 50,
    city: "Laguna Beach",
    state: "CA",
    country: "USA",
    bib: "281",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Max Metcalfe",
    gender: "M",
    age: 32,
    city: "Lafayette",
    state: "CA",
    country: "USA",
    bib: "283",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Turdl Miller",
    gender: "M",
    age: 52,
    city: "St George",
    state: "UT",
    country: "USA",
    bib: "284",
    entryType: "Lottery (64 tickets)",
    wsFinishes: 0
  },
  {
    name: "Jeffrey Moore",
    gender: "M",
    age: 47,
    city: "Biloxi",
    state: "MS",
    country: "USA",
    bib: "285",
    entryType: "Lottery (1 ticket)",
    wsFinishes: 0
  },
  {
    name: "Shalise Morgan",
    gender: "F",
    age: 46,
    city: "Pleasant Grove",
    state: "UT",
    country: "USA",
    bib: "286",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Marcin Mrowka",
    gender: "M",
    age: 51,
    city: "Brooklyn",
    state: "NY",
    country: "USA",
    bib: "287",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Todd Yerkes",
    gender: "M",
    age: 52,
    city: "St George",
    state: "UT",
    country: "USA",
    bib: "289",
    entryType: "Lottery (64 tickets)",
    wsFinishes: 0
  },
  {
    name: "Josam Mulinyawe",
    gender: "M",
    age: 43,
    city: "San Jose",
    state: "CA",
    country: "USA",
    bib: "290",
    entryType: "Raffle",
    wsFinishes: 1
  },
  {
    name: "Sean Mullett",
    gender: "M",
    age: 53,
    city: "Cleveland",
    state: "TN",
    country: "USA",
    bib: "291",
    entryType: "Lottery (64 tickets)",
    wsFinishes: 1
  },
  {
    name: "Dan Newman",
    gender: "M",
    age: 52,
    city: "San Francisco",
    state: "CA",
    country: "USA",
    bib: "294",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "John Nobles",
    gender: "M",
    age: 36,
    city: "Tulsa",
    state: "OK",
    country: "USA",
    bib: "296",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Selina Nordberg",
    gender: "F",
    age: 53,
    city: "La Habra",
    state: "CA",
    country: "USA",
    bib: "297",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Melissa Ossanna",
    gender: "F",
    age: 52,
    city: "Bar Harbor",
    state: "ME",
    country: "USA",
    bib: "298",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Panya Somnhot",
    gender: "M",
    age: 39,
    city: "Ashland",
    state: "OR",
    country: "USA",
    bib: "300",
    entryType: "Lottery (128 tickets)",
    wsFinishes: 0
  },
  {
    name: "Justin Peake",
    gender: "M",
    age: 41,
    city: "Severna Park",
    state: "MD",
    country: "USA",
    bib: "301",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Michael Poole",
    gender: "M",
    age: 40,
    city: "Arden",
    state: "NC",
    country: "USA",
    bib: "302",
    entryType: "Lottery (128 tickets)",
    wsFinishes: 0
  },
  {
    name: "Azam Poulatov",
    gender: "M",
    age: 44,
    city: "Brooklyn",
    state: "NY",
    country: "USA",
    bib: "303",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Scott Anderson",
    gender: "M",
    age: 60,
    city: "Clayton",
    state: "CA",
    country: "USA",
    bib: "304",
    entryType: "Raffle",
    wsFinishes: 0
  },
  {
    name: "Marty Reed",
    gender: "M",
    age: 50,
    city: "West Vancouver",
    state: "BC",
    country: "CAN",
    bib: "305",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Andrew Reiff",
    gender: "M",
    age: 48,
    city: "North Bend",
    state: "WA",
    country: "USA",
    bib: "306",
    entryType: "Sponsor",
    wsFinishes: 0
  },
  {
    name: "Justin Kinner",
    gender: "M",
    age: 34,
    city: "Casper",
    state: "WY",
    country: "USA",
    bib: "307",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Tex Ritter",
    gender: "M",
    age: 64,
    city: "Grass Valley",
    state: "CA",
    country: "USA",
    bib: "309",
    entryType: "Race Admin",
    wsFinishes: 0
  },
  {
    name: "Stephen Robbins",
    gender: "M",
    age: 37,
    city: "Elkin",
    state: "NC",
    country: "USA",
    bib: "310",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Anthony Molina",
    gender: "M",
    age: 50,
    city: "West Sacramento",
    state: "CA",
    country: "USA",
    bib: "311",
    entryType: "Race Admin",
    wsFinishes: 0
  },
  {
    name: "Dan Foley",
    gender: "M",
    age: 59,
    city: "El Dorado Hills",
    state: "CA",
    country: "USA",
    bib: "314",
    entryType: "Race Admin",
    wsFinishes: 0
  },
  {
    name: "Scott Sambucci",
    gender: "M",
    age: 47,
    city: "Davis",
    state: "CA",
    country: "USA",
    bib: "315",
    entryType: "Race Admin",
    wsFinishes: 0
  },
  {
    name: "Barry Hauptman",
    gender: "M",
    age: 58,
    city: "Bethesda",
    state: "MD",
    country: "USA",
    bib: "316",
    entryType: "Lottery (64 tickets)",
    wsFinishes: 0
  },
  {
    name: "Takuji Sasaki",
    gender: "M",
    age: 45,
    city: "Hayamacho",
    state: "JPN",
    country: "JPN",
    bib: "318",
    entryType: "Lottery (1 ticket)",
    wsFinishes: 0
  },
  {
    name: "Sarah Seads",
    gender: "F",
    age: 45,
    city: "Courtenay",
    state: "BC",
    country: "CAN",
    bib: "320",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Patrick Service",
    gender: "M",
    age: 37,
    city: "Palm Springs",
    state: "CA",
    country: "USA",
    bib: "321",
    entryType: "Raffle",
    wsFinishes: 0
  },
  {
    name: "Scott Slater",
    gender: "M",
    age: 43,
    city: "Guilford",
    state: "CT",
    country: "USA",
    bib: "322",
    entryType: "Lottery (128 tickets)",
    wsFinishes: 0
  },
  {
    name: "Kevin Smilor",
    gender: "M",
    age: 38,
    city: "Denver",
    state: "CO",
    country: "USA",
    bib: "323",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "David Smith",
    gender: "M",
    age: 55,
    city: "Santa Rosa",
    state: "CA",
    country: "USA",
    bib: "324",
    entryType: "Raffle",
    wsFinishes: 1
  },
  {
    name: "Kirk Smith",
    gender: "M",
    age: 41,
    city: "Freeville",
    state: "NY",
    country: "USA",
    bib: "325",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Thomas Somers",
    gender: "M",
    age: 51,
    city: "South Amboy",
    state: "NJ",
    country: "USA",
    bib: "326",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Tim Spaulding",
    gender: "M",
    age: 41,
    city: "Forest",
    state: "VA",
    country: "USA",
    bib: "327",
    entryType: "Lottery (2 tickets)",
    wsFinishes: 1
  },
  {
    name: "Dan Spearin",
    gender: "M",
    age: 48,
    city: "Crozet",
    state: "VA",
    country: "USA",
    bib: "328",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Eric Spector",
    gender: "M",
    age: 74,
    city: "Palo Alto",
    state: "CA",
    country: "USA",
    bib: "329",
    entryType: "Sponsor",
    wsFinishes: 0
  },
  {
    name: "Vagn Steen",
    gender: "M",
    age: 64,
    city: "Brecksville",
    state: "OH",
    country: "USA",
    bib: "331",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Nathan Stroh",
    gender: "M",
    age: 49,
    city: "Klamath Falls",
    state: "OR",
    country: "USA",
    bib: "332",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Taylor Sheridan",
    gender: "F",
    age: 29,
    city: "Reno",
    state: "NV",
    country: "USA",
    bib: "333",
    entryType: "Race Admin",
    wsFinishes: 0
  },
  {
    name: "Ram Subbaraman",
    gender: "M",
    age: 40,
    city: "Sunnyvale",
    state: "CA",
    country: "USA",
    bib: "334",
    entryType: "Lottery (2 tickets)",
    wsFinishes: 0
  },
  {
    name: "Mike Tamoush",
    gender: "M",
    age: 42,
    city: "San Diego",
    state: "CA",
    country: "USA",
    bib: "336",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Andrew Taylor",
    gender: "M",
    age: 37,
    city: "Modesto",
    state: "CA",
    country: "USA",
    bib: "337",
    entryType: "Race Admin",
    wsFinishes: 1
  },
  {
    name: "Kelly Teeselink",
    gender: "F",
    age: 34,
    city: "Flagstaff",
    state: "AZ",
    country: "USA",
    bib: "338",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Gabriel Tenyenhuis",
    gender: "M",
    age: 53,
    city: "Clovis",
    state: "CA",
    country: "USA",
    bib: "339",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Philippe Thenot",
    gender: "M",
    age: 48,
    city: "Marietta",
    state: "GA",
    country: "USA",
    bib: "340",
    entryType: "Lottery (2 tickets)",
    wsFinishes: 0
  },
  {
    name: "Bill Thom",
    gender: "M",
    age: 60,
    city: "Chicago",
    state: "IL",
    country: "USA",
    bib: "343",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 6
  },
  {
    name: "Tom Tighe",
    gender: "M",
    age: 46,
    city: "Pulaski",
    state: "NY",
    country: "USA",
    bib: "344",
    entryType: "Lottery (1 ticket)",
    wsFinishes: 0
  },
  {
    name: "Malin Timbs",
    gender: "F",
    age: 45,
    city: "Nashville",
    state: "TN",
    country: "USA",
    bib: "345",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Denis Trafecanty",
    gender: "M",
    age: 78,
    city: "Santa Ysabel",
    state: "CA",
    country: "USA",
    bib: "347",
    entryType: "Raffle",
    wsFinishes: 5
  },
  {
    name: "John Truelove",
    gender: "M",
    age: 53,
    city: "Terre Haute",
    state: "IN",
    country: "USA",
    bib: "348",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Damon Valenzona",
    gender: "M",
    age: 34,
    city: "San Diego",
    state: "CA",
    country: "USA",
    bib: "350",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Robert Vanderlip",
    gender: "M",
    age: 59,
    city: "San Ramon",
    state: "CA",
    country: "USA",
    bib: "352",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Geoff Vaughan",
    gender: "M",
    age: 56,
    city: "Corte Madera",
    state: "CA",
    country: "USA",
    bib: "353",
    entryType: "Race Admin",
    wsFinishes: 3
  },
  {
    name: "Christine Velasquez",
    gender: "F",
    age: 49,
    city: "Portland",
    state: "OR",
    country: "USA",
    bib: "355",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Christine Vincent",
    gender: "F",
    age: 49,
    city: "Roseville",
    state: "CA",
    country: "USA",
    bib: "357",
    entryType: "Race Admin",
    wsFinishes: 0
  },
  {
    name: "Scott Vosburg",
    gender: "M",
    age: 51,
    city: "Loomis",
    state: "CA",
    country: "USA",
    bib: "358",
    entryType: "Race Admin",
    wsFinishes: 4
  },
  {
    name: "Claire Walla",
    gender: "F",
    age: 36,
    city: "Spokane",
    state: "WA",
    country: "USA",
    bib: "359",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Dreama Walton",
    gender: "F",
    age: 40,
    city: "Colorado Springs",
    state: "CO",
    country: "USA",
    bib: "360",
    entryType: "Lottery (2 tickets)",
    wsFinishes: 0
  },
  {
    name: "James Wang",
    gender: "M",
    age: 34,
    city: "Redding",
    state: "CA",
    country: "USA",
    bib: "361",
    entryType: "Race Admin",
    wsFinishes: 0
  },
  {
    name: "Michael Weston",
    gender: "M",
    age: 60,
    city: "Honolulu",
    state: "HI",
    country: "USA",
    bib: "363",
    entryType: "Race Admin",
    wsFinishes: 0
  },
  {
    name: "Johanan Wilson",
    gender: "M",
    age: 45,
    city: "Cool",
    state: "CA",
    country: "USA",
    bib: "367",
    entryType: "Sponsor",
    wsFinishes: 0
  },
  {
    name: "Spencer Punter",
    gender: "M",
    age: 50,
    city: "Burlingame",
    state: "CA",
    country: "USA",
    bib: "369",
    entryType: "Raffle",
    wsFinishes: 0
  },
  {
    name: "Isaac Wait",
    gender: "M",
    age: 44,
    city: "Ona",
    state: "WV",
    country: "USA",
    bib: "370",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 1
  },
  {
    name: "Jiaju Zhao",
    gender: "M",
    age: 26,
    city: "beijing",
    state: "CHN",
    country: "CHN",
    bib: "371",
    entryType: "Lottery (1 ticket)",
    wsFinishes: 0
  },
  {
    name: "Brian Pickett",
    gender: "M",
    age: 41,
    city: "Kingsport",
    state: "TN",
    country: "USA",
    bib: "372",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Jay Smithberger",
    gender: "M",
    age: 51,
    city: "Yellow Springs",
    state: "OH",
    country: "USA",
    bib: "373",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 1
  },
  {
    name: "Kuni Yamagata",
    gender: "M",
    age: 68,
    city: "Citrus Heights",
    state: "CA",
    country: "USA",
    bib: "374",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 2
  },
  {
    name: "Kristin Carbajal",
    gender: "F",
    age: 42,
    city: "Upland",
    state: "CA",
    country: "USA",
    bib: "376",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Jason Brock",
    gender: "M",
    age: 45,
    city: "Kaysville",
    state: "UT",
    country: "USA",
    bib: "378",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Denise Calcagnino",
    gender: "F",
    age: 53,
    city: "North Syracuse",
    state: "NY",
    country: "USA",
    bib: "379",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Mike Rohner",
    gender: "M",
    age: 41,
    city: "Dubuque",
    state: "IA",
    country: "USA",
    bib: "380",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Doug Camann",
    gender: "M",
    age: 53,
    city: "Clarksboro",
    state: "NJ",
    country: "USA",
    bib: "381",
    entryType: "Lottery (64 tickets)",
    wsFinishes: 0
  },
  {
    name: "David Holliday",
    gender: "M",
    age: 58,
    city: "Cincinnati",
    state: "OH",
    country: "USA",
    bib: "382",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 1
  },
  {
    name: "Andy Burger",
    gender: "M",
    age: 41,
    city: "Granite Bay",
    state: "CA",
    country: "USA",
    bib: "383",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Lacey Frerking",
    gender: "F",
    age: 37,
    city: "Fernley",
    state: "NV",
    country: "USA",
    bib: "384",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Kyle Curtin",
    gender: "M",
    age: 34,
    city: "Durango",
    state: "CO",
    country: "USA",
    bib: "387",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Joseph Bearss",
    gender: "M",
    age: 51,
    city: "Arvada",
    state: "CO",
    country: "USA",
    bib: "388",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Ryan Weibel",
    gender: "M",
    age: 48,
    city: "South Lake Tahoe",
    state: "CA",
    country: "USA",
    bib: "389",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 1
  },
  {
    name: "Joey Cohen",
    gender: "M",
    age: 38,
    city: "Shaker Heights",
    state: "OH",
    country: "USA",
    bib: "390",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Jason Crowe",
    gender: "M",
    age: 48,
    city: "Newark",
    state: "IL",
    country: "USA",
    bib: "392",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Nobuya Takahashi",
    gender: "M",
    age: 40,
    city: "Richmond",
    state: "CA",
    country: "USA",
    bib: "393",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Kevin Fox",
    gender: "M",
    age: 40,
    city: "Midland",
    state: "TX",
    country: "USA",
    bib: "394",
    entryType: "Lottery (1 ticket)",
    wsFinishes: 0
  },
  {
    name: "Peter Newton",
    gender: "M",
    age: 40,
    city: "Boulder",
    state: "CO",
    country: "USA",
    bib: "396",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Steven Holmes Jr.",
    gender: "M",
    age: 40,
    city: "West Olive",
    state: "MI",
    country: "USA",
    bib: "397",
    entryType: "Lottery (1 ticket)",
    wsFinishes: 0
  },
  {
    name: "Elan Lieber",
    gender: "M",
    age: 31,
    city: "Richmond",
    state: "CA",
    country: "USA",
    bib: "398",
    entryType: "Lottery (32 tickets)",
    wsFinishes: 0
  },
  {
    name: "Scott Bowman",
    gender: "M",
    age: 49,
    city: "Signal Mountain",
    state: "TN",
    country: "USA",
    bib: "399",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Jake Kruse",
    gender: "M",
    age: 40,
    city: "Glen Burnie",
    state: "MD",
    country: "USA",
    bib: "400",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Sarah Bunting Lamos",
    gender: "F",
    age: 46,
    city: "Thornton",
    state: "CO",
    country: "USA",
    bib: "401",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Mike Soltys",
    gender: "M",
    age: 54,
    city: "Omaha",
    state: "NE",
    country: "USA",
    bib: "402",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Rod Farvard",
    gender: "M",
    age: 25,
    city: "Mammoth Lakes",
    state: "CA",
    country: "USA",
    bib: "403",
    entryType: "Lottery (2 tickets)",
    wsFinishes: 0
  },
  {
    name: "Dylan Broderick",
    gender: "F",
    age: 30,
    city: "Montpelier",
    state: "VT",
    country: "USA",
    bib: "405",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Mike Groenewegen",
    gender: "M",
    age: 33,
    city: "Seattle",
    state: "WA",
    country: "USA",
    bib: "406",
    entryType: "Lottery (1 ticket)",
    wsFinishes: 0
  },
  {
    name: "Michael Emerling",
    gender: "M",
    age: 51,
    city: "Chattanooga",
    state: "TN",
    country: "USA",
    bib: "408",
    entryType: "Lottery (2 tickets)",
    wsFinishes: 0
  },
  {
    name: "Mark Bain",
    gender: "M",
    age: 44,
    city: "Cleveland Heights",
    state: "OH",
    country: "USA",
    bib: "409",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Steve Hawkins",
    gender: "M",
    age: 43,
    city: "Grand Canyon",
    state: "AZ",
    country: "USA",
    bib: "410",
    entryType: "Lottery (64 tickets)",
    wsFinishes: 0
  },
  {
    name: "Wolfgang Hielscher",
    gender: "M",
    age: 49,
    city: "Bordesholm",
    state: "DEU",
    country: "DEU",
    bib: "411",
    entryType: "Lottery (1 ticket)",
    wsFinishes: 0
  },
  {
    name: "Tom Pluim",
    gender: "M",
    age: 43,
    city: "Suffolk",
    state: "VA",
    country: "USA",
    bib: "412",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Brian Myers",
    gender: "M",
    age: 44,
    city: "Asheville",
    state: "NC",
    country: "USA",
    bib: "414",
    entryType: "Lottery (4 tickets)",
    wsFinishes: 0
  },
  {
    name: "Timothy Buse",
    gender: "M",
    age: 36,
    city: "Louisville",
    state: "CO",
    country: "USA",
    bib: "415",
    entryType: "Lottery (64 tickets)",
    wsFinishes: 0
  },
  {
    name: "Christoffer Johnston",
    gender: "M",
    age: 35,
    city: "Warren",
    state: "NJ",
    country: "USA",
    bib: "416",
    entryType: "Lottery (2 tickets)",
    wsFinishes: 0
  },
  {
    name: "Stephanie Johnson",
    gender: "F",
    age: 58,
    city: "Knoxville",
    state: "TN",
    country: "USA",
    bib: "417",
    entryType: "Lottery (16 tickets)",
    wsFinishes: 0
  },
  {
    name: "Nicholas Horvath",
    gender: "M",
    age: 36,
    city: "Chattanooga",
    state: "TN",
    country: "USA",
    bib: "418",
    entryType: "Lottery (8 tickets)",
    wsFinishes: 0
  },
  {
    name: "Gabriel Wildberger",
    gender: "M",
    age: 33,
    city: "Mönchaltorf",
    state: "CHE",
    country: "CHE",
    bib: "419",
    entryType: "Lottery (1 ticket)",
    wsFinishes: 0
  }
];

//{name: "$1 $2", gender: "$3", age: $4, city: "$5", state: "$6", country: "$7", bib: "$8", entryType: "$9", wsFinishes: $10 },
export default athletes.map((a) => ({
  id: a.name.replaceAll(" ", "-").toLowerCase(),
  ...a
}));
