import React from "react";
import bkg_3 from "./bkg_3.jpg";

export default class Canvas extends React.Component {
  componentDidMount() {
    setTimeout(() => this.draw(), 100);
    setTimeout(() => this.draw(), 1500);
    setTimeout(() => this.draw(), 5500);
    setTimeout(() => this.draw(), 10000);
  }
  draw() {
    const { races } = this.props;
    this.drawImage(races);
  }
  drawImage(races = []) {
    const distanceDescribers = races.reduce((acc, race) => {
      acc[race.distanceDescriber] = acc[race.distanceDescriber] || 0;
      acc[race.distanceDescriber] = acc[race.distanceDescriber] + 1;
      return acc;
    }, {});
    const describerList = Object.keys(distanceDescribers)
      .map(key => ({
        key,
        amt: distanceDescribers[key]
      }))
      .sort((a, b) => a.key.localeCompare(b.key))
      .sort((a, b) => (a.amt >= b.amt ? -1 : 1))
      .slice(0, 4)
      .map(x => ({ key: x.key.slice(2), amt: x.amt }))
      .reverse();

    var ctx = this.canvas.getContext("2d");
    ctx.fillStyle = "#00204D";
    ctx.drawImage(this.bkg_3, 0, 0, 1080, 1080);

    ctx.font = "24px Open Sans";
    let extraLines = 0;
    races.forEach((race, i) => {
      ctx.fillStyle = "#57B9F3";
      ctx.fillText(
        race.time,
        639 - ctx.measureText(race.time).width,
        415 + 39 * (i + extraLines)
      );
      ctx.fillStyle = "#F3F5F9";
      if (race.distance && race.distance.length > 0) {
        ctx.fillText(`(${race.distance})`, 645, 415 + 39 * (i + extraLines));
      }
      ctx.fillStyle = "#F3F5F9";
      const raceNameWidth = ctx.measureText(race.name).width;
      const splits = Math.ceil(raceNameWidth / 409);
      if (splits > 1) {
        const parts = race.name.split(/\s/);
        const lines = [];
        let currentLine = "";
        parts.forEach(part => {
          const nextCurrentLineWidth = ctx.measureText(currentLine + " " + part)
            .width;
          if (nextCurrentLineWidth < 409) {
            if (currentLine.length > 0) {
              currentLine += " " + part;
            } else {
              currentLine += part;
            }
          } else {
            lines.push(currentLine);
            currentLine = part;
          }
        });
        lines.push(currentLine);
        lines.forEach((line, lineI) => {
          ctx.fillText(line, 128, 415 + 39 * (i + extraLines + lineI));
        });
        extraLines += lines.length - 1;
      } else {
        ctx.fillText(race.name, 128, 415 + 39 * (i + extraLines));
      }
    });

    let offset = 0;
    if (describerList.length === 3) {
      offset = 100;
    } else if (describerList.length === 2) {
      offset = 200;
    } else if (describerList.length === 1) {
      offset = 300;
    }
    describerList.forEach(({ key, amt }, i) => {
      ctx.fillStyle = "#FFFFFF";
      ctx.font = "125px Bebas Neue";
      ctx.fillText(
        amt,
        940 - ctx.measureText(amt).width / 2,
        245 + offset + 215 * i
      );
      ctx.fillStyle = "#F05537";
      ctx.font = "32px Bebas Neue";
      ctx.fillText(
        key,
        940 - ctx.measureText(key).width / 2,
        290 + offset + 215 * i
      );
    });

    this.props.setURL(
      this.canvas
        .toDataURL("image/jpeg")
        .replace("image/png", "image/octet-stream"),
      "Racing"
    );
  }
  render() {
    return (
      <div style={{ padding: 10 }}>
        <img
          ref={x => (this.bkg_3 = x)}
          src={bkg_3}
          alt={"bkg_3"}
          style={{ display: "none" }}
          onLoad={this.forceUpdate}
        />
        <canvas
          ref={x => (this.canvas = x)}
          style={{ width: "100%", maxWidth: 500, maxHeight: 500 }}
          height="1080"
          width="1080"
        ></canvas>
      </div>
    );
  }
}
