import React from "react";
import bkg_1 from "./bkg_story_2.jpg";

export default class Canvas extends React.Component {
  state = { blur: true };
  componentDidMount() {
    setTimeout(() => {
      this.draw();
      this.setState({ blur: false });
    }, 100);
    setTimeout(() => {
      this.draw();
    }, 150);
    setTimeout(() => this.draw(), 1500);
    setTimeout(() => this.draw(), 5500);
    setTimeout(() => this.draw(), 10000);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.unit !== this.props.unit ||
      this.props.art !== prevProps.art
    ) {
      setTimeout(() => {
        this.draw();
        this.setState({ blur: false });
      }, 100);
    }
  }
  draw() {
    const {
      totalMiles,
      totalMeters,
      totalSecondsMoving,
      activityCount,
      prCount,
      kudoCount,
      unit,
      longestRunMi,
      longestRunM,
      largestClimbFt,
      largestClimbM
    } = this.props;
    this.drawImage(
      Math.round(totalMiles),
      Math.round(totalMeters / 1000),
      activityCount,
      prCount,
      kudoCount,
      Math.round(totalSecondsMoving / 60 / 60),
      longestRunMi.toFixed(1),
      (longestRunM / 1000).toFixed(1),
      Math.round(largestClimbFt),
      Math.round(largestClimbM),
      unit
    );
  }
  drawImage(
    miles = 2030,
    kilometers = 3000,
    runs = 232,
    prs = 230,
    kudos = 134,
    hours = 244,
    longestRunMi = 1.2,
    longestRunKm = 3.1,
    largestClimbFt = 100,
    largestClimbM = 50,
    unit
  ) {
    if (this.props.art) {
      this.canvas.style.letterSpacing = "100px";
    } else {
      this.canvas.style.letterSpacing = "";
    }
    var ctx = this.canvas.getContext("2d");

    if (this.state.blur) {
      ctx.filter = "blur(10px)";
    } else {
      ctx.filter = "none";
    }

    ctx.fillStyle = "#00204D";
    ctx.drawImage(this.bkg_1, 0, 0, 1080, 1920);

    const rolls = Math.ceil(miles * 14.08);
    ctx.fillStyle = "#57B9F3";
    ctx.font = "42px Bebas Neue";
    var startQuote = 1425;
    ctx.fillText(
      "This year You ran",
      830 - ctx.measureText("This year You ran").width / 2 + 22,
      startQuote
    );
    ctx.fillText(
      "equivalant to",
      830 - ctx.measureText("equivalant to").width / 2 + 22,
      startQuote + 45
    );
    ctx.fillText(
      `${rolls.toLocaleString()} rolls of`,
      830 -
        ctx.measureText(`${rolls.toLocaleString()} rolls of`).width / 2 +
        22,
      startQuote + 45 * 2
    );
    ctx.fillText(
      `toilet paper.`,
      830 - ctx.measureText(`toilet paper.`).width / 2 + 22,
      startQuote + 45 * 3
    );

    ctx.fillStyle = this.props.art ? "#FF0000" : "#FFFFFF";
    ctx.font = "200px Bebas Neue";
    const distanceText = this.props.unit === "miles" ? miles : kilometers;
    ctx.fillText(
      distanceText,
      350 - ctx.measureText(distanceText).width / 2 + 10,
      445
    );

    ctx.fillStyle = this.props.art ? "#FF0000" : "#FF9700";
    ctx.font = "72px Bebas Neue";
    const unitText = this.props.unit === "miles" ? "MILES" : "KILOMETERS";
    ctx.fillText(unitText, 350 - ctx.measureText(unitText).width / 2 + 25, 535);

    ctx.fillStyle = "#FFFFFF";
    ctx.font = "125px Bebas Neue";
    ctx.fillText(runs, 849 - ctx.measureText(runs).width / 2, 435);
    ctx.fillStyle = "#FF9700";
    ctx.font = "32px Bebas Neue";
    ctx.fillText("RUNS", 849 - ctx.measureText("RUNS").width / 2, 435 + 43);

    ctx.fillStyle = "#FFFFFF";
    ctx.font = "125px Bebas Neue";
    ctx.fillText(hours, 849 - ctx.measureText(hours).width / 2, 665);
    ctx.fillStyle = "#FF9700";
    ctx.font = "32px Bebas Neue";
    ctx.fillText("HOURS", 849 - ctx.measureText("HOURS").width / 2, 665 + 43);

    ctx.fillStyle = "#FFFFFF";
    ctx.font = "125px Bebas Neue";
    ctx.fillText(
      unit === "miles" ? longestRunMi : longestRunKm,
      849 -
        ctx.measureText(unit === "miles" ? longestRunMi : longestRunKm).width /
          2,
      891
    );
    ctx.fillStyle = "#FF9700";
    ctx.font = "32px Bebas Neue";
    ctx.fillText(
      "LONGEST RUN",
      849 - ctx.measureText("LONGEST RUN").width / 2,
      891 + 43
    );

    ctx.fillStyle = "#FFFFFF";
    ctx.font = "125px Bebas Neue";
    ctx.fillText(
      unit === "miles" ? largestClimbFt : largestClimbM,
      849 -
        ctx.measureText(unit === "miles" ? largestClimbFt : largestClimbM)
          .width /
          2 -
        22,
      1135
    );
    ctx.font = "64px Bebas Neue";
    ctx.fillText(
      unit === "miles" ? "FT" : "M",
      849 -
        ctx.measureText(unit === "miles" ? largestClimbFt : largestClimbM)
          .width /
          2 +
        ctx.measureText(unit === "miles" ? largestClimbFt : largestClimbM)
          .width +
        ctx.measureText(unit === "miles" ? "FT" : "M").width -
        22,
      1135
    );
    ctx.fillStyle = "#FF9700";
    ctx.font = "32px Bebas Neue";
    ctx.fillText(
      "LARGEST CLIMB",
      849 - ctx.measureText("LARGEST CLIMB").width / 2,
      1135 + 43
    );

    this.props.setURL(
      this.canvas
        .toDataURL("image/jpeg")
        .replace("image/png", "image/octet-stream"),
      "Story"
    );
  }
  render() {
    return (
      <div style={{ padding: 10 }}>
        <img
          ref={(x) => (this.bkg_1 = x)}
          src={bkg_1}
          alt={"bkg_1"}
          style={{ display: "none" }}
          onLoad={this.forceUpdate}
        />
        <div style={{ position: "relative" }}>
          <canvas
            ref={(x) => (this.canvas = x)}
            style={{
              width: "100%",
              maxWidth: 350,
              maxHeight: 350 * (1920 / 1080),
              background: "#290143"
            }}
            height="1920"
            width="1080"
          ></canvas>
        </div>
      </div>
    );
  }
}
