import React from "react";
import bkg_1 from "./bkg_1.jpg";
import Loader from "./Loader";

export default class Canvas extends React.Component {
  state = { blur: true };
  componentDidMount() {
    setTimeout(() => {
      this.draw();
      this.setState({ blur: false });
    }, 100);
    setTimeout(() => {
      this.draw();
    }, 150);
    setTimeout(() => this.draw(), 1500);
    setTimeout(() => this.draw(), 5500);
    setTimeout(() => this.draw(), 10000);
  }
  draw() {
    const {
      totalMiles,
      totalMeters,
      totalSecondsMoving,
      activityCount,
      races,
      prCount,
      kudoCount,
      unit
    } = this.props;
    this.drawImage(
      Math.round(totalMiles),
      Math.round(totalMeters / 1000),
      activityCount,
      prCount,
      kudoCount,
      races.length,
      Math.round(totalSecondsMoving / 60 / 60)
    );
  }
  drawImage(
    miles = 2030,
    kilometers = 3000,
    runs = 232,
    prs = 230,
    kudos = 134,
    races = 5,
    hours = 244
  ) {
    var ctx = this.canvas.getContext("2d");

    if (this.state.blur) {
      ctx.filter = "blur(10px)";
    } else {
      ctx.filter = "none";
    }

    ctx.fillStyle = "#00204D";
    ctx.drawImage(this.bkg_1, 0, 0, 1080, 1080);

    const laps = Math.ceil(miles * 4);
    ctx.fillStyle = "#F05537";
    ctx.font = "42px Bebas Neue";
    ctx.fillText(
      "This year You ran equivalant to running",
      1080 / 2 -
        ctx.measureText("This year You ran equivalant to running").width / 2 +
        22,
      95
    );
    ctx.fillText(
      `${laps} times around the track`,
      1080 / 2 -
        ctx.measureText(`${laps} times around the track`).width / 2 +
        22,
      140
    );

    ctx.fillStyle = "#FFFFFF";
    ctx.font = "200px Bebas Neue";
    const distanceText = this.props.unit === "miles" ? miles : kilometers;
    ctx.fillText(
      distanceText,
      1080 / 2 - ctx.measureText(distanceText).width / 2 + 22,
      510
    );

    ctx.fillStyle = "#F05537";
    ctx.font = "50px Bebas Neue";
    const unitText = this.props.unit === "miles" ? "MILES" : "KILOMETERS";
    ctx.fillText(
      unitText,
      1080 / 2 - ctx.measureText(unitText).width / 2 + 25,
      570
    );

    ctx.fillStyle = "#FFFFFF";
    ctx.font = "125px Bebas Neue";
    ctx.fillText(runs, 228 - ctx.measureText(runs).width / 2, 870);
    ctx.fillStyle = "#F05537";
    ctx.font = "32px Bebas Neue";
    ctx.fillText("RUNS", 228 - ctx.measureText("RUNS").width / 2, 913);

    ctx.fillStyle = "#FFFFFF";
    ctx.font = "125px Bebas Neue";
    ctx.fillText(kudos, 435 - ctx.measureText(kudos).width / 2, 870);
    ctx.fillStyle = "#F05537";
    ctx.font = "32px Bebas Neue";
    ctx.fillText("KUDOS", 435 - ctx.measureText("KUDOS").width / 2, 913);

    ctx.fillStyle = "#FFFFFF";
    ctx.font = "125px Bebas Neue";
    ctx.fillText(races, 642 - ctx.measureText(races).width / 2, 870);
    ctx.fillStyle = "#F05537";
    ctx.font = "32px Bebas Neue";
    ctx.fillText("RACES", 642 - ctx.measureText("RACES").width / 2, 913);

    ctx.fillStyle = "#FFFFFF";
    ctx.font = "125px Bebas Neue";
    ctx.fillText(hours, 848 - ctx.measureText(hours).width / 2, 870);
    ctx.fillStyle = "#F05537";
    ctx.font = "32px Bebas Neue";
    ctx.fillText("HOURS", 848 - ctx.measureText("HOURS").width / 2, 913);

    this.props.setURL(
      this.canvas
        .toDataURL("image/jpeg")
        .replace("image/png", "image/octet-stream"),
      "Summary"
    );
  }
  render() {
    return (
      <div style={{ padding: 10 }}>
        <img
          ref={x => (this.bkg_1 = x)}
          src={bkg_1}
          alt={"bkg_1"}
          style={{ display: "none" }}
          onLoad={this.forceUpdate}
        />
        <div style={{ position: "relative" }}>
          <canvas
            ref={x => (this.canvas = x)}
            style={{
              width: "100%",
              maxWidth: 500,
              maxHeight: 500,
              background: "#290143"
            }}
            height="1080"
            width="1080"
          ></canvas>
        </div>
      </div>
    );
  }
}
