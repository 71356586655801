import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { default as ai } from "./athletes";
import styled, { useTheme } from "styled-components";

import CircularProgress from "@material-ui/core/CircularProgress";
import Link from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import { MathComponent } from "mathjax-react";

import Auth from "../../Auth";
import "react-tabs/style/react-tabs.css";

const firebase = require("firebase");

const Styled = {
  Card: styled.div`
    border-radius: 4px;
    background: #17406d;
    padding: 32px;
    margin: 8px;
    h2 {
      margin: 0;
      padding: 0;
      text-align: center;
    }
  `,
  TeamTable: styled.div`
    max-width: 100%;
    h3 {
      margin: 0;
      padding: 8px 0;
    }
  `,
  PickBanner: styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    padding: 8px;
    z-index: 99;
    background: ${(props) => (props.draftComplete ? "#0F0" : "#F00")};
    color: ${(props) => (props.draftComplete ? "#000" : "#FFF")};
  `
};

const WS2021 = () => {
  const [athletes, setAthletes] = useState();
  const [user, setUser] = useState();
  const [userData, setUserData] = useState();
  const [picks, setPicks] = useState();
  const [howItWorksOpen, setHowItWorksOpen] = useState(false);
  const [pickMode, setPickMode] = useState();
  const [loading, setLoading] = useState({});
  const theme = useTheme();
  const fullScreen = useMediaQuery("(max-width:540px)");

  const db = () => {
    return firebase.firestore().collection("fantasy").doc("ws2021");
  };
  useEffect(() => {
    /* ai.forEach((a) => {
      db().collection("athletes").doc(a.id).set(a);
    }); */
  }, []);
  useEffect(() => {
    db()
      .collection("athletes")
      .onSnapshot((snapshots) => {
        const athletes = [];
        snapshots.forEach((resp) => {
          const data = resp.data();
          athletes.push({ ...data, id: resp.id });
        });
        setAthletes(athletes);
      });
  }, []);
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      console.log("user", user);
      setUser(user);
    });
  }, []);
  useEffect(() => {
    if (user?.uid) {
      db()
        .collection("users")
        .doc(user.uid)
        .onSnapshot((user) => {
          const u = user.data() || {};
          setUserData(u.data || {});
          setPicks(u || {});
        });
    }
  }, [user]);

  const draft = (id, gender, place) => {
    const draft = firebase.functions().httpsCallable("fantasyWS2021-draft");
    setLoading({ ...loading, [id]: true });
    draft({ id, gender, place }).then(({ data }) => {
      //setPicks(data);
      setPickMode(false);
      setLoading({ ...loading, [id]: false });
    });
  };
  /*
  const drop = (id) => {
    const drop = firebase.functions().httpsCallable("fantasyWS2021-drop");
    setLoading(id);
    setLoading({ ...loading, [id]: true });
    drop({ id }).then(({ data }) => {
      //setPicks(data);
      setLoading({ ...loading, [id]: false });
    });
  };
*/
  const auth = () => {
    const w = window.open("/auth", "auth", "height=200,width=200");
  };

  const hasDraftedAthlete = (picks = {}, athleteId) => {
    for (var i = 0; i < 8; i++) {
      if (picks[`M${i}`] === athleteId || picks[`F${i}`] === athleteId) {
        return true;
      }
    }
  };

  const draftStatus = (userPicks) => {
    let womenLeft = 0;
    let menLeft = 0;
    [0, 1, 2, 3, 4, 5, 6, 7].forEach((i) => {
      if (!userPicks[`M${i}`]) {
        menLeft++;
      }
      if (!userPicks[`F${i}`]) {
        womenLeft++;
      }
    });
    return { menLeft, womenLeft };
  };

  const renderTeam = (userPicks, title, gender) => {
    return [0, 1, 2, 3, 4, 5, 6, 7].map((i) => {
      const athleteId = userPicks[`${gender}${i}`] || {};
      const athlete = athletes.find(({ id }) => id === athleteId);
      if (athlete) {
        return (
          <TableRow key={athlete.id}>
            <TableCell align="left" style={{ width: "100%" }}>
              <b>#{i + 1}</b> {athlete.name}
            </TableCell>
            <TableCell align="right">
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => setPickMode({ gender, place: i })}
              >
                Replace
              </Button>
            </TableCell>
          </TableRow>
        );
      } else {
        return (
          <TableRow key={"empty" + i}>
            <TableCell align="left">
              <b>#{i + 1}</b> Empty
            </TableCell>
            <TableCell align="right">
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setPickMode({ gender, place: i })}
              >
                Pick
              </Button>
            </TableCell>
          </TableRow>
        );
      }
    });
  };

  const renderAthletes = (athletes, picks, gender, place) => {
    return (
      <TableContainer component={Paper} style={{ heigth: "100%" }}>
        <Table stickyHeader size="small" aria-label="">
          <TableHead>
            <TableRow>
              <TableCell align="left">Bib</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="center">Age</TableCell>
              <TableCell align="right">Draft</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {athletes
              .filter(({ id, gender: g }) => {
                return g === gender && !hasDraftedAthlete(picks, id);
              })
              .sort((a, b) =>
                parseInt(a.bib.replace(/^\D+/g, "")) <
                parseInt(b.bib.replace(/^\D+/g, ""))
                  ? -1
                  : 1
              )
              .map((a) => {
                return (
                  <AthleteRow
                    canPick={true}
                    loading={loading}
                    draft={draft}
                    place={place}
                    {...a}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  if (user === null) {
    return (
      <div>
        <Auth />
      </div>
    );
  }

  if (athletes && userData && picks && user) {
    const status = draftStatus(picks);
    const draftComplete = status.menLeft === 0 && status.womenLeft === 0;
    return (
      <div style={{ padding: 8, paddingTop: 60 }}>
        <div>
          <h1 style={{ textAlign: "center", fontSize: 24 }}>
            Western States Fantasy
          </h1>
        </div>
        <div style={{ textAlign: "center", fontSize: 18 }}>
          This competition is over, thanks for playing!
        </div>

        {false && (
          <>
            <div style={{ textAlign: "center" }}>
              <Link
                color="#45ffff"
                style={{ color: "#45ffff", textDecoration: "underline" }}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setHowItWorksOpen(true);
                }}
              >
                How It Works
              </Link>
            </div>
            <Dialog
              open={howItWorksOpen}
              onClose={() => setHowItWorksOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullScreen={fullScreen}
            >
              <DialogTitle id="alert-dialog-title">
                <div>How It Works</div>
                <IconButton
                  style={{
                    position: "absolute",
                    right: 8,
                    top: 8
                  }}
                  aria-label="close"
                  onClick={() => setHowItWorksOpen(false)}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <ul>
                      <li>Create a Year In Running account or login.</li>
                      <li>
                        Make your top 8 predictions for both the men's and the
                        women's race ***DATE/TIME HERE***.
                      </li>
                      <li>Follow along on race day!</li>
                    </ul>
                    <h2 style={{ fontSize: 14 }}>Scoring</h2>
                    <ul>
                      <li>
                        Get 1 point if your runner actually makes it in the top
                        8.
                      </li>
                      <li>
                        If your runner makes it in the top 8, get{" "}
                        <MathComponent
                          tex={String.raw`8-\left|actual - predicted\right|`}
                        />
                        .
                      </li>
                      <li>
                        ex: If you pick a runner in 2nd, and they place 6th, you
                        get
                        <MathComponent
                          tex={String.raw`8-\left|6-2\right| = (8 - 4) = 4`}
                        />
                      </li>
                      <li>
                        ex: If you pick a runner in 5th, and they place 4th, you
                        get
                        <MathComponent
                          tex={String.raw`8-\left|4-5\right| = (8 - 1) = 7`}
                        />
                      </li>
                      <li>
                        If you correctly pick a runner's place, get 11-x points
                        for predicting a runner's actual place where "x" is the
                        runner's place.
                      </li>
                      <li>
                        ex: if you pick 4th place correct, get{" "}
                        <MathComponent tex={String.raw`11 - 4 = 7`} />
                      </li>
                      <li>
                        ex: if you pick 1st place correct, get
                        <MathComponent tex={String.raw`11 - 1 = 10`} />
                      </li>
                      <li>
                        ex: if you pick 8th place correct, get
                        <MathComponent tex={String.raw`11 - 8 = 3`} />
                      </li>
                      <li>Bonus 10 points for picking 1, 2, 3 correctly.</li>
                      <li>
                        Bonus 50 points + automatic bragging rights for perfect
                        picks
                      </li>
                    </ul>
                  </div>
                </DialogContentText>
              </DialogContent>
            </Dialog>

            <Dialog
              open={pickMode}
              onClose={() => setPickMode(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullScreen={fullScreen}
            >
              <DialogTitle id="alert-dialog-title">
                <div>{`Select Your #${(pickMode?.place || 0) + 1} ${
                  (pickMode || {}).gender === "M" ? "Men's" : "Women's"
                } Pick`}</div>{" "}
                <IconButton
                  style={{
                    position: "absolute",
                    right: 8,
                    top: 8
                  }}
                  aria-label="close"
                  onClick={() => setPickMode(false)}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent style={{ padding: 0 }}>
                <DialogContentText id="alert-dialog-description">
                  {renderAthletes(
                    athletes,
                    picks,
                    pickMode?.gender,
                    pickMode?.place
                  )}
                </DialogContentText>
              </DialogContent>
            </Dialog>

            <Styled.TeamTable>
              <h3 style={{ textAlign: "center", marginTop: 16 }}>Your Team</h3>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  margin: "auto",
                  textAlign: "center",
                  justifyContent: "center"
                }}
              >
                <TableContainer
                  component={Paper}
                  style={{
                    width: 400,
                    maxWidth: "100%",
                    margin: 4
                  }}
                >
                  <Table stickyHeader size="small" aria-label="">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" colSpan={2}>
                          Men
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {renderTeam(picks, "Men's Team", "M")}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer
                  component={Paper}
                  style={{
                    width: 400,
                    maxWidth: "100%",
                    margin: 4
                  }}
                >
                  <Table stickyHeader size="small" aria-label="">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" colSpan={2}>
                          Women
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {renderTeam(picks, "Women's Team", "F")}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Styled.TeamTable>
            <Styled.PickBanner draftComplete={draftComplete}>
              {!draftComplete ? (
                <>
                  <div>
                    <b>Team Not Complete</b>
                  </div>
                  <div>
                    {8 - status.menLeft}/8 men picked • {8 - status.womenLeft}/8
                    women picked
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <b>Team Complete!</b>
                  </div>
                  <div>Check back on June 26 to follow your athletes!</div>
                </>
              )}
            </Styled.PickBanner>
          </>
        )}
      </div>
    );
  }

  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <CircularProgress />
      </div>
    </div>
  );
};
const AthleteRow = ({
  loading,
  canPick,
  drafted,
  id,
  name,
  gender,
  age,
  city,
  state,
  country,
  bib,
  entryType,
  wsFinishes,
  drop,
  draft,
  place
}) => {
  return (
    <TableRow key={id}>
      <TableCell align="left">{bib}</TableCell>
      <TableCell align="left">{name}</TableCell>
      <TableCell align="center">{age}</TableCell>
      <TableCell align="right">
        <Button
          size="small"
          variant="contained"
          color={"primary"}
          disabled={loading[id] || (!drafted && !canPick)}
          onClick={() => draft(id, gender, place)}
        >
          Draft
        </Button>
      </TableCell>
    </TableRow>
  );
};
export default WS2021;
