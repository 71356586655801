import React from "react";
import { Button } from "@material-ui/core";
import Instagram from "@material-ui/icons/Instagram";

export default () => {
  return (
    <Button
      variant="contained"
      style={{
        color: "#F05537",
        background: "#FFF",
        fontWeight: "700",
        textTransform: "capitalize"
      }}
      href="https://www.instagram.com/yearinrunning/"
      target="_blank"
      startIcon={<Instagram />}
    >
      Let's Connect!
    </Button>
  );
};
